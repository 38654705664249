<template>
    <div>
        <div class="search">
            <div class="container-fluid">
                <form class="search-form" @submit.prevent>
                    <div v-if="title" class="form-title hide-on-mobile">
                        <h1>{{ title }}</h1>
                    </div>
                    <div v-if="showCalendar" class="form-element is-calendar">
                        <div class="date">
                            <button type="button" class="btn btn-icon btn-link" @click="previousDay" title="Termine am vorherigen Tag anzeigen">
                                <i class="material-icons" aria-hidden="true">keyboard_arrow_left</i>
                            </button>

                            <span class="date-selected">
                                {{readableDate}}
                            </span>

                            <DatePicker class="inline-block h-full" v-model="dateTimeSelection.selectedDate" @dayclick="dateOrTimeChanged">
                                <template v-slot="{ togglePopover }">
                                    <button type="button" class="btn btn-icon btn-link" @click="togglePopover()" title="Kalendertag auswählen">
                                        <i class="material-icons" aria-hidden="true">calendar_today</i>
                                    </button>
                                </template>
                            </DatePicker>

                            <button type="button" class="btn btn-icon btn-link" @click="nextDay" title="Termine am nächsten Tag anzeigen">
                                <i class="material-icons" aria-hidden="true">keyboard_arrow_right</i>
                            </button>
                        </div>
                    </div>
                    <div v-else class="form-element search-element">
                        <input type="text" class="search-icon" placeholder="Suche..." aria-label="Suchwort eingeben" v-model="query.selectedKeyword" @change="queryChanges">
                    </div>

                    <div v-if="showCalendar" :class="['form-element hide-on-mobile time']">
                      <select class="time-icon" name="time" data-placeholder="Uhrzeit" aria-label="Zeitspanne auswählen" v-model="dateTimeSelection.selectedTimeFrame" @change="dateOrTimeChanged">
                        <option v-for="(elem,index) in options" :key="index" :value="elem.value">{{ elem.label }}</option>
                    </select>
                </div>


                <div :class="['form-element hide-on-mobile']">
                    <select name="categories" data-placeholder="Kategorien" aria-label="Kategorie auswählen" v-model="query.selectedCategory" @change="queryChanges">
                        <option v-for="cat in categories" :key="cat.id" :value="cat.value">{{ cat.label }}</option>
                    </select>
                </div>

                <div class="form-element hide-on-mobile">
                    <select name="tags" data-placeholder="Tags" aria-label="Stichwort auswählen" v-model="query.selectedTag" @change="queryChanges">
                        <option v-for="tag in tags" :key="tag.id" :value="tag.value">{{ tag.label }}</option>
                    </select>
                </div>
            </form>
        </div>
    </div>
    <FilterButton @reload="filterChanged" v-if="title!='Suche'"/>
</div>
</template>

<script>
    import { DatePicker } from 'v-calendar';
    import FilterButton from '@/components/controls/FilterButton.vue';
    import { getWeekday, getMonth } from '@/utils/helpers';

    export default {
        name: 'Search',
        components: {
            DatePicker,
            FilterButton,
        },
        props: {
            showCalendar: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            keyword: {
                type: String,
                default: ''
            }
        },
        emits: ['reload','dateTimeChanged'],
        data() {
            return {
                date: null,
                currentDate: null,
                options : [{label:"Uhrzeit",value:null},{label:"Vormittags (8:00-12:00)",value:"vormittags"},{label:"Nachmittags (12:00-17:00)",value:"nachmittags"},{label:"Abends (ab 17:00)",value:"abends"}],
                query:{
                    selectedTag: null,
                    selectedKeyword: null,
                    selectedCategory: null,
                },
                dateTimeSelection:{
                    selectedDate: new Date(),
                    selectedTimeFrame: null
                }
            }
        },
        computed: {
            categories(){
                return this.$store.getters.getCategories;
            },
            tags(){
                return this.$store.getters.getTags;
            },
            readableDate(){
                if(this.dateTimeSelection!=null){
                    var d = this.dateTimeSelection.selectedDate==null?new Date():new Date(this.dateTimeSelection.selectedDate);
                    var day = getWeekday(d.getDay());
                    var month = getMonth(d.getMonth()+1).substring(0,3);
                    var result = day+', '+d.getDate()+'.'+' '+month+' '+d.getFullYear();
                    return result;
                }
                return null;
            }
        },
        mounted(){
            this.query.selectedKeyword = this.keyword;
        },
        methods: {
            getMonth,
            getWeekday,
            nextDay(){
                var d = this.dateTimeSelection.selectedDate==null?new Date():new Date(this.dateTimeSelection.selectedDate);
                d.setDate(d.getDate()+1);
                this.dateTimeSelection.selectedDate = d;
                this.dateOrTimeChanged();
            },
            previousDay(){
                var d = this.dateTimeSelection.selectedDate==null?new Date():new Date(this.dateTimeSelection.selectedDate);
                d.setDate(d.getDate()-1);
                this.dateTimeSelection.selectedDate = d;
                this.dateOrTimeChanged();
            },
            queryChanges(){
                this.$emit('reload',this.query);
            },
            filterChanged(query){
                this.query.selectedKeyword = query.selectedKeyword;
                if(query.selectedCategory!==undefined){
                    this.query.selectedCategory = query.selectedCategory;
                }
                //console.log(query.selectedTags);
                if(query.selectedTags!==undefined){
                 this.query.selectedTag = query.selectedTags;
             }
             this.queryChanges();
         },
         dateOrTimeChanged(){
            this.$emit('dateTimeChanged',this.dateTimeSelection);
        }
    }
}
</script>
<style lang="scss" scoped>

    @import '@/scss/_variables.scss';

    .search {
        position: relative;
        z-index: 2;
        padding: 105px 0 60px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        box-shadow: 0px 5px 10px 0px rgba($color: $primary, $alpha: 0.3);

        @media (max-width: 990px){
            padding: 105px 0 30px;
        }

        &-form {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 60px;

            @media (max-width: 990px){
                flex-wrap: wrap;
            }

            .form-title {
                margin-right: 40px;

                @media (max-width: 1200px){
                    margin-right: 10px;
                }

                @media (max-width: 990px){
                    width: 45%!important;
                    margin-bottom: 20px;
                }

                h1{
                    font-size: 20px;
                }

            }

            .form-element {
                width: 286px;
                margin: 0 20px;

                @media (max-width: 1200px){
                    margin: 0 10px;
                }

                @media (max-width: 990px){
                    width: 45%!important;
                    margin-bottom: 20px!important;
                    margin-left: 0px!important;
                }

                @media (max-width: 786px){
                    width: 100%!important;
                    margin-bottom: auto!important;
                    margin-left: auto!important;
                }

                &.search-element{
                    @media (max-width: 786px){
                        margin-right: 30px;
                        margin-left: 10px!important;
                    }
                }

                &.is-calendar{
                    display: inline-flex;
                    justify-content: space-around;

                    .date {
                        display: inline-flex;
                        width: 286px;
                        vertical-align: middle;
                        align-items: center;
                        align-content: center;
                        justify-content: space-between;

                        @media (max-width: 390px){
                            width: 260px !important;
                        }

                        &-selected {
                            font-family: 'elliot-regular', sans-serif;
                            font-size: 16px;
                            font-style: normal;
                            line-height: 28px;
                            font-weight: 700;
                            letter-spacing: 0em;
                            text-align: center;

                            @media (max-width: 390px) {
                                font-size: 15px !important;
                            }
                        }

                        @media (max-width: 550px) {
                            order: 2;
                        }
                    }

                    .time {
                        flex: 1;
                        width: 180px;
                        margin-left: 20px;

                        @media (max-width: 550px) {
                            order: 1;
                            margin-left: 0;
                            margin-bottom: 20px;
                        }
                    }

                    @media (max-width: 550px) {
                        flex-direction: column;
                        align-items: center;
                        margin-left: 0;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                @media (max-width: 1050px) {
                    margin: 0 10px;
                    width: 230px;
                }

                @media (max-width: 767px) {
                    margin: 0;
                    width: 286px;
                }

                @media (max-width: 500px) {
                    width: 100%;
                }
            }

            @media (max-width: 767px) {
                justify-content: flex-end;
            }
        }

        @media (max-width: 786px) {
            padding: 95px 0 30px;
        }
    }
</style>