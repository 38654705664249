<template>
    <div class="card">
        <h5 class="card-title">Busanbindung</h5>

        <form action="" class="connection">
            <div class="connection__item">
                <i class="material-icons" aria-hidden="true">gps_fixed</i>
                <input type="text"
                v-model="theStart"
                placeholder="Start eingeben ..." />
            </div>

            <div class="connection__item">
                <i class="material-icons" aria-hidden="true">room</i>
                <input type="text"
                :value="ziel"
                @input="ziel = $event.target.value" 
                placeholder="Ende eingeben ..." />
            </div>

            <div class="connection__item">
                <div
                @click="openNahSH()"
                @keyup.enter="openNahSH()"
                tabindex="0"
                class="btn btn-2 icon-before"
                title="Busverbindung suchen">
                <i class="material-icons" aria-hidden="true">search</i>
                Verbindung suchen
            </div>
        </div>
    </form>
</div>
</template>

<script>

    export default {
        name: "CardConnection",
        props: {
          address: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return {
            theStart: '',
            theTarget: '',
        }
    },
    computed: {
        ziel: {
            get: function () {
                return this.theTarget==""?this.address:this.theTarget;
            },
            set: function (newValue) {
                this.theTarget=newValue;
            }
        }
    },
    mounted() {
        this.ziel = this.address;
    },
    methods:{
        openNahSH(){
            var link = 'https://nah.sh.hafas.de/bin/query.exe/dn?url=%2Fhome%2FTimeTableForm';
            if(this.theStart!=''){
                link +="&S="+this.theStart;
            }
            if(this.ziel!=""){
                link +="&Z="+this.ziel;
            }
            window.open(link);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.card{
    position: relative;
    border-radius: 5px;
    border-bottom-left-radius: 30px;
    border: none;
    padding: 3rem;
    box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
    
    &-title {
        margin-bottom: 1.5rem;
    }

    .centered {
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
    }

    .connection {
        display: block;
        max-width: 350px;
        margin: 0 auto;

        &__item {
            width: 100%;
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            margin-bottom: 10px;

            .material-icons {
                display: inline-block;
                align-self: center;
                width: 40px;
            }

            .btn {
                margin-left: 40px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: 500px) {
        padding: 1rem;
        margin-left: -15px;
        margin-right: -15px;
        box-shadow: none;
    }
}

</style>
