<template>
   <div class="teilen-knopf-box profile-heading__icon">

    <navigator-share class="social-inline__item flex-fill"
    v-if="isItMobile && webShareApiSupported"
    v-bind:url="currentPath"
    v-bind:title="'Kiel.KulturKompass - echt Kult, echt Kiel!'"
    v-bind:on-error="onShareError"
    v-bind:on-success="onShareSuccess"
    >
    <template v-slot:clickable>
        <button class="btn btn-round teilen-knopf">
            <i class="material-icons" aria-hidden="true">share</i>
        </button>
    </template>
</navigator-share>

<a :href="shareViaEmail" v-else class="btn btn-round special teilen-knopf" title="Teile den Inhalt mit anderen">
    <i class="material-icons" aria-hidden="true">share</i>
</a>

</div>

</template>
<script>
    import NavigatorShare from 'vue-navigator-share';
    import { isMobile } from 'mobile-device-detect';


    export default {
        name: "shareButton",
        components: {
            NavigatorShare
        },
        data() {
            return {
                isItMobile: isMobile ? true : false,
            }
        },
        computed: {
            currentPath(){
                return window.location.href;
            },
            shareViaEmail(){
                return "mailto:?body="+this.currentPath;
            },
            webShareApiSupported() {
                return navigator.share
            },
        },
        methods: {
            onShareError(err) {
                alert(err);
                console.log(err);
            },
            onShareSuccess(err) {
                console.log(err);
            },
        }
    }
</script>

<style lang="scss" >

    .teilen-knopf-box .teilen-knopf{
        color: #00183E!important;
        background-color: #FFF !important;
        border: 2px solid #00183E;
    }

    .teilen-knopf:hover, .teilen-knopf:active {
        color: #FFF !important;
        background-color: #00183E!important;
        border: 2px solid #00183E !important;
    }

    .teilen-knopf:focus-visible {
        border: 2px dashed #E30059 !important;
        border-radius: 100px !important;
        background-color: #FFF;
    }

    .teilen-knopf:focus-visible:before {
      background: none;
      border: 2px solid #00183E;
      border-radius: 100px;
      content: "";
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      pointer-events: none;
  }

</style>
