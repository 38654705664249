<template>
    <div class="breadcrumb">
        <button ref="focusMe" class="btn btn-link btn-back" @click="goBack" title="Zurück">
            <img src="/assets/icons/left-arrow.png" alt="Zurück">
        </button>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumb',
        mounted(){
            this.$nextTick(this.$refs.focusMe.focus());
        },
        methods: {
            goBack() {
                window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .breadcrumb{
        position: absolute;
        background: transparent;
        width: auto;
        padding: 0;
        margin: 0;
        padding-left: 15px;
        top: 100px;
        z-index: 99;

        @media (min-width: 1200px) {
            left: 20px;
        }

        @media (max-width: 786px) {
            top: 90px;
        }

        @media (max-width: 350px) {
            top: 90px;
        }

        button:focus,button:focus-visible{
            border-radius: 50%!important;
        }
    }
</style>