<template>

  <the-modal @closeModal="closeModal" :isBig=true :showHeader=true>

    <template v-slot:header>
      <h5>Neue Liste anlegen</h5>
    </template>

    <template v-slot:body>

      <div>
        <div id="create-list-row" class="row justify-content-center align-items-center">

          <div class="form-group" v-if="institution!=null">
            <select class="form-select mb20" v-model="form.type">
              <option v-for="(item) in options" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
          </div>

          <div class="form-group">
            <div class="inner-addon">
              <input type="text" class="form-control" @blur="validateListTitle" v-model="form.name" placeholder="Name der Liste"/>
            </div>
            <span class="validation-error" v-show="showListNameError">Geben Sie einen Namen für die Liste ein.</span>
          </div>

          <div class="form-group mt20">
            <button @click="dispatchCreateCollection" style="width: 100%;" class="btn btn-fill">Liste anlegen</button>
          </div>

        </div>
      </div>

    </template>

  </the-modal>

</template>
<script>
import TheModal from '@/components/modal/show.vue';
import Resource from '@/api/resource';
import { showLoader, hideLoader } from '@/utils/helpers';
const collectionResource = new Resource('collections');
import checkPermission from '@/utils/permission';

export default {
  name: 'createCollectionModal',
  components: {
    TheModal,
  },
  data() {
    return {
      loader: null,
      showListNameError: false,
      options: [{
        value: 'privat',
        label: 'Private Merkliste'
      }], 
      form: {
        name: "",
        app: null,
        description: '',
        type: 'privat',
      },
    }
  },
  mounted(){
    if(this.institution!= null){
      if(this.checkPermission({elem: this.institution ,permission: 'createCollections'})){
        this.options.push({value: 'institution', label: 'Merkliste meiner Institution'});
      }
    }

  },
  computed: {
    user(){
      return this.$store.state.user;
    },
    institution(){
      if(this.user.institution!=null){
        return this.user.institution;
      }
      return null;
    },
  },
  methods: {
    showLoader,
    hideLoader,
    checkPermission,
    validateListTitle(){
      if(!this.form.name!=""){
        this.showListNameError=true;
        return false;
      }
      else{
        this.showListNameError=false;
        return true;
      }
    },
    dispatchCreateCollection() {

      if(!this.validateListTitle()){
        return;
      }

      this.loader = this.showLoader(this.loader);

      var data = {'content':null,'collection':this.form};

      collectionResource.store(data)
      .then(() => {

        this.$notify({
          duration: 2500,
          title: 'Liste angelegt!',
          text: 'Die Liste wurde erfolgreich gespeichert.'
        });

        /*we need to reload the user to get the permissions*/
        this.$store.dispatch('loadUser');

      })
      .finally(() => {
        this.loader = this.hideLoader(this.loader);
        this.closeModal();
      });
    },
    closeModal(){
      this.$emit("closeModal");
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

</style>
