<template>

  <the-modal v-if="showIntro" @closeModal="closeModal" :isBig="true" :showHeader="false">

    <template v-slot:body>

      <splide ref="teaserCarousel" class="full-width">

        <splide-slide class="full-width">
          <center class="mt10 mb20"><h5>Ahoi und willkommen an Bord!</h5></center>
          <span style="text-align: center;">Finde kulturelle Einrichtungen über die Karte. Veranstaltungen hast du am besten mit dem Kalender im Blick.<span style="display:none;">Die KulTouren zeigen dir Themen-Spaziergänge durch Kiels Stadtteile.</span></span>
          <img style="width: 100%;" src="/assets/intro/intro-img-1.png" alt="Intro-Img 1"/>
        </splide-slide>

        <splide-slide class="full-width">
          <center class="mt10 mb20"><h5>Kultur für später merken</h5></center>
          <span style="text-align: center;">Nutze den Anker-Button, um dir Inhalte für später zu merken. Erstelle persönliche Listen, um deine Favoriten nach Themen zu sortieren.</span>
          <img style="width: 100%; padding: 30px 25px;" src="/assets/intro/OnboardingAnker.png" alt="Intro-Img 2"/>
        </splide-slide>

      </splide>

    </template>
    <template v-slot:footer>
      <div class="btn btn-fill mt20" @click="closeModal">
        Alles klar
      </div>
    </template>
  </the-modal>

</template>
<script>
import TheModal from '@/components/modal/show.vue';

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default {
  name: 'introModal',
  components: {
    TheModal,
    Splide,
    SplideSlide,
  },
  data() {
    return {
      loader: null,
      showIntro: true,
    }
  },
  methods: {
    closeModal(){
      this.showIntro = false;
      this.$emit("closeModal");
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

</style>
