<template>
    <div class="profile koultour footer-margin">
        <Breadcrumb />

        <div class="container">

            <div class="koultour-header">

                <div class="koultour-image" :style="{backgroundImage: `url(${collectionImage.image})` }"><span class="copyright" v-if="collectionImage.copyright!=null">&copy; {{collectionImage.copyright}}</span>
                    <div class="news-category">
                        <span>Audiowalk</span>
                    </div>
                    <button title="Merken" class="btn btn-link btn-favorite special" :class="isInCollectionClass" @click="showAddToCollectionDialog=true">
                        <i class="material-icons" aria-hidden="true">anchor</i>
                    </button>
                </div>



                <div class="koultour-heading" v-if="collection!=null">
                    <div class="d-flex justify-content-between">
                        <div class="koultour-heading__title">
                            <h4>{{collectionTitle}}</h4>
                            <ul class="koultour-heading__features">
                                <li class="stations">
                                    <span v-if="isset(stations)">{{ numberOfContents }} Stationen</span>
                                </li>
                                <li class="distance">
                                    <img src="assets/icons/width.svg" class="mr-1" alt="icon" >
                                    <span>{{ distance }} km</span>
                                </li>
                                <li>
                                    <img src="assets/icons/walk.svg" height="22px;" alt="icon" >
                                    <span>{{ footPath}} min</span>
                                </li>
                                <li>
                                    <img src="assets/icons/bicycle.svg" class="mr-1" alt="icon" >
                                    <span>{{ bikePath }} min</span>
                                </li>
                            </ul>
                        </div>

                        <share-button class="share-btn" :is-device-mobile="isItMobile"/>

                    </div>
                </div>

            </div>

            <div class="koultour-map mt30" v-if="collection!=null">
                <the-map :collection="collection" :contents="stations":allowUserGPSAccess="allowUserGPSAccess" style="height: 600px;" class="full-width map-display"></the-map>
                <center v-if="!allowUserGPSAccess"><button class="btn btn-fill mt20 mb10 location-button" @click.prevent="showGpsModule=true" style="margin: 0 auto;">Wo bin ich?</button></center>
            </div>

            <div class="koultour-text mt30" v-if="audioWalk!==null">
                <AudioCard :audioWalk="audioWalk"/>
            </div>

            <div class="koultour-text mt30" v-if="collection!=null && description !==null">
                <CardSimple :description="description" :introduction="introduction" />
            </div>

            <div class="way-points mt50" v-if="isset(orderedStations)">
                <div v-for="(station,index) in orderedStations" :key="index">
                    <WayPoint v-if="station!==null && station.content_type_name!=='transition'" :content="station" distance="100" :showDot="showDot(index)"/>
                    <Transition v-else-if="station!== null && station.content_type_name=='transition'" :content="station" :showDot="false" />
                </div>
            </div>

            <div>
                <MoreTours v-if="this.collection!==null && this.showCollection" :currentId="this.collection.id" />
            </div>
        </div>

        <gps-modal v-if="showGpsModule" @active="allowUserGPSAccess=true" @closeModal="showGpsModule=false"/>

        <AddContentToCollection :content="collection" v-if="showAddToCollectionDialog" @closeModal="showAddToCollectionDialog=false"></AddContentToCollection>

    </div>
</template>

<script>
    import Resource from '@/api/resource';
    import { latLng } from 'leaflet';
    import { getFieldValues, showLoader, hideLoader, mapOrder, json_decode } from '@/utils/helpers';
    import { parseTravelTime, isset } from '@/utils/helpers';
    import { isMobile } from 'mobile-device-detect';

    const contentResource = new Resource('contents');

    export default {
        name: 'Kulturspur',
        components: {
            TheMap: () =>import('./components/tourMap.vue'),
            MoreTours: () =>import('./components/moreTours.vue'),
            WayPoint: () =>import('./components/WayPoint.vue'),   
            Transition: () =>import('./components/transition.vue'),   
            GpsModal: () =>import('./components/gpsModal.vue'),            
            CardSimple: () =>import('@/components/cards/CardSimple.vue'),
            AudioCard: () =>import('@/components/cards/CardAudio.vue'),
            Breadcrumb: () =>import('@/components/controls/Breadcrumb.vue'),
            ShareButton: () =>import('@/components/controls/ShareButton.vue'),
            AddContentToCollection: () =>import('@/components/modal/addContentToCollection/dialog.vue'),

        },
        data() {
            return {
                loader: null,
                showAddToCollectionDialog: false,
                showCollection: true,
                distances: null,
                collection: null,
                showGpsModule: false,
                allowUserGPSAccess: false,
                isItMobile: isMobile ? true : false,
                stations: [],
                orderedStations: []
            }
        },
        mounted() {
          navigator.permissions.query({name:'geolocation'}).then(result => {
            if(result.state == "granted"){
                console.log("granted");
                this.allowUserGPSAccess = true;
          }
      });
      },
      computed: {
          id() {
            return this.$route.params.id;
        },
        collections(){
          return this.$store.getters.getCollections;
      },
      collectionTitle(){
        if(this.collection!==null){
            return this.collection.title;
        }
        return "";
    },
    collectionImage: function() {
        if(this.collection!=null){
            var image = getFieldValues(this.collection,'teaser-bild');
            if(image!=null){
                image = json_decode(image);
                if(typeof image === "object"){
                    return {image:this.$backendUrl+image.path, copyright:image.copyright,description:image.description};
                }
                else { 
                    image = image.charAt(0)!="/"?"/"+image:image;   
                    return {image:this.$backendUrl+image, copyright:null,description:null};
                }
            }
        }
        return {image:"/assets/news/news-1.jpg", copyright:null,description:null};
    },
    metaObject(){
        if(this.collection!=null && Object.prototype.hasOwnProperty.call(this.collection,'meta')){
            return JSON.parse(this.collection.meta);
        }
        return null;
    },
    numberOfContents(){
        if(this.collection!=null){
            let stations = getFieldValues(this.collection, 'beziehungen_zu_anderen_inhalten');
            if(isset(stations)){
              return stations.length;
          }
      }
      return 0;
  },
  bikePath: function() {
    if(this.collection!==null) {
        return getFieldValues(this.collection,'dauer_mit_dem_rad_in_minuten');
    }
    return null;
},
footPath: function() {
    if(this.collection!==null) {
        return getFieldValues(this.collection,'dauer_zu_fu_in_minuten');
    }
    return null;
},
distance(){
    if(this.collection!==null){
        return getFieldValues(this.collection,'weglnge_in_km');
    }
    return null;
},
stationIds(){
    if(this.collection!==null){
        return getFieldValues(this.collection,'beziehungen_zu_anderen_inhalten');
    }
    return null;
},
introduction(){
    if(this.collection!==null){
        return getFieldValues(this.collection,'einleitung');
    }
    return null;
},
description(){
    if(this.collection!==null){
        return getFieldValues(this.collection,'body');
    }
    return null;
},
audioWalk(){
    if(this.collection!==null){
        return getFieldValues(this.collection,'audiowalk');
    }
    return null;
},
contents(){
    if(this.collection!=null && this.collection.contents!=null){
        return this.collection.contents;
    }
    return null;
},
isLoggedIn(){
    if(this.$store.getters.getToken){
        return true;
    }
    return false;
},
isLoggedIn(){
    if(this.$store.getters.getToken){
        return true;
    }
    return false;
},
isInCollectionClass(){
    if(this.isLoggedIn && this.isContentInCollection()){
        return 'active';
    }
    return '';
},
    /*
     contentsWithAddress(){
        if(this.contents == null){
            return null;
        }
        var theContents = [];
        for(var i = 0; i<this.contents.length; i++){
            if(this.hasAddress(this.contents[i])){
                theContents.push(this.contents[i]);
            }  
        }
        return theContents;
    },
    */

},
watch: {
    id: {
        handler: function(newId,oldId) {
            this.showCollection = false;
            this.getCollection(newId);
            this.scrollToTop();
        },
        immediate: true
    },
    stations(newStations) {
        this.orderedStations = this.orderStations(this.stations,this.stationIds);
    }
},
methods: {
    json_decode,
    showLoader, 
    hideLoader,
    mapOrder,
    getFieldValues,
    parseTravelTime,
    isset,
    isContentInCollection(){
        if(this.collections!=null){
            for (var prop in this.collections) {
                var collectionGroup = this.collections[prop];
                for(var i=0; i<collectionGroup.length; i++){
                    var currentCollection = collectionGroup[i];
                    if(currentCollection!=null && Object.prototype.hasOwnProperty.call(currentCollection,'contents') && currentCollection.contents.length>0){
                        for(var j=0; j<currentCollection.contents.length; j++){
                            if(currentCollection.contents[j].id == this.collection.id){
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    },
    includeLatLongInContent(content){
        content.latLng = null;
        if(content.content_type_name!=='geschichte'){
            var adresse = getFieldValues(content,'adresse');
            if(isset(adresse) && isset(adresse.latitude) && isset(adresse.longitude)){
                content.latLng = latLng(adresse.latitude,adresse.longitude);
            }
        } 
        else{
            this.getAttractionAdress(content).then(() => {
                if(content.parentContent !== null && content.parentContent.latLng !== null){
                    content.latLng = content.parentContent.latLng;
                }
            });
        }  
    },
    async getAttractionAdress(content){
        content.parentContent = null;
        if(content.content_type_name==='geschichte' && content!==null){
            var attractionId = getFieldValues(content, 'sehenswrdigkeit');
            await contentResource.get(attractionId)
            .then(response => {
              content.parentContent = response.data;
              this.includeLatLongInContent(content.parentContent);
              if(content.parentContent !== null){
                content.images = getFieldValues(content.parentContent, 'teaser-bild');
            }
        });
        }
    },
    showDot(index){
        if(isset(this.orderedStations)){
            if(index == 0 || index == this.orderedStations.length-1){
                return false;
            }
            return true;
        }
    },
    scrollToTop() {
        window.scrollTo(0,0);
    },
    getCollection(id) {
        this.loader = this.showLoader(this.loader);
        contentResource.get(id)
        .then(response => {
          this.collection = response.data;
          this.showCollection = true;
          this.getStations();
      })
        .finally(() => {
            this.loader = this.hideLoader(this.loader);
        });
    },
    getStations(){
        var stations = [];
        if(this.collection!==null){
            var stationIds = this.stationIds;

            if(isset(stationIds)){
                for(let i=0; i<stationIds.length; i++){
                    contentResource.get(stationIds[i]).then(response => {
                        var station = response.data;
                        station.images = null;
                        stations.push(station);
                        this.includeLatLongInContent(station);
                    })
                    .catch(error => {
                      /*console.log(error);*/
                    });
                }
            }
        }
        this.stations = stations;
    },
    orderStations(stations,order){
        //return stations;
        var results =  [];
        if(isset(stations)){
            for(var i = 0; i < stations.length; i++){
                if(stations[i]!==null){
                    var cont = stations.find(x => x.id === parseInt(order[i]));
                    results.push(cont);
                }
            }
            return results;
        }
        return null;
    }, 
    hasAddress(content){
      var adresse = getFieldValues(content,'adresse');
      if(adresse!=null && adresse.latitude!=null && adresse.longitude!=null){
        return true;
    }
    return false;
},
}
}
</script>

<style lang="scss">
    @import '@/scss/_variables.scss';

    .koultour {
        position: relative;
        background: #fff;
        padding-top: 110px;
        padding-bottom: 40px;

        .container {
            max-width: 1000px;
        }

        .koultour-header{
            @media (max-width: 767px) {
                padding: 0px 20px;
            }
        }

        &-image {
            position: relative;
            z-index: 1;
            height: 368px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            @media (max-width: 767px) {
                margin-left: -15px;
                margin-right: -15px;
            }

            @media (max-width: 560px) {
                height: 240px;
            }

            @media (max-width: 480px) {
                height: 200px;
            }

            @media (max-width: 420px) {
                height: 180px;
            }

            .news-category {
                position: absolute;
                top: 40px;
                right: 0px;
                background: rgba($color: #E30059, $alpha: 0.85);
                padding: 10px 40px;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;

                span {
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-size: 16px;
                    color: #fff;
                    line-height: 1.25;
                    font-family: 'elliot-bold', sans-serif;
                    font-weight: 700;
                }
            }
        }

        &-heading {
            position: relative;
            z-index: 2;
            top: 0;
            padding: 10px 20px;
            box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
            border-radius: 5px;
            border-bottom-left-radius: 30px;

            @media (max-width: 767px) {
                margin-left: -15px;
                margin-right: -15px;
            }

            .share-btn a{

                @media (max-width: 465px){
                    top: 20px;
                }
            }

            &__features {
                margin-top: 10px;
                margin-bottom: 10px;

                li {
                    display: inline-flex;
                    vertical-align: middle;
                    align-content: center;
                    margin-right: 30px;

                    @media (max-width: 465px){

                    margin-right:10px;
                }

                i.material-icons {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    @media (max-width: 767px) {
        padding-top: 70px;
    }

    @media (max-width: 350px) {
        padding-top: 60px;
    }
}

.stations {
    @media(max-width:991px){
        width: 100%;
    }
}

.distance {
    @media(max-width: 500px){
        width: 100%;
    }
}

.map-display {
    @media(max-width: 991px){
        height: 400px !important;
    }
}

.location-button:focus-visible {
    border-color: #000 !important;
}
</style>
