<template>
  <div class="change-password footer-margin">
    <Breadcrumb />

    <div class="container-fluid blank">
      <div id="change-password-row" class="row justify-content-center align-items-center blank-section">
        <div id="change-password-column">
          <div id="change-password-box" class="col-12">

            <h3 class="text-center text-info">Passwort ändern</h3>

            <div class="form-group">
              <div class="inner-addon left-addon">
                <i class="glyphicon material-icons icon" aria-hidden="true">lock</i>
                <input type="password" class="form-control"  @blur="validateOldPasswordInput" v-model="credentials.oldPassword" placeholder="Bisheriges Passwort"/>
              </div>
              <span class="validation-error" v-show="showOldPasswordError">Geben Sie Ihr bisheriges Passwort ein.</span>
            </div>

            <div class="form-group mb-2">
              <div class="inner-addon left-addon">
                <i class="glyphicon material-icons icon" aria-hidden="true">lock</i>
                <input type="password" class="form-control"  @blur="validatePasswordInput" v-model="credentials.password" placeholder="Neues Passwort"/>
              </div>
              <span class="validation-error" v-show="showPasswordError">Geben Sie ein Passwort ein. Beachten Sie dabei die unten stehenden Anforderungen.</span>
            </div>

            <div class="mb-2 pw-req">Mindestens 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl</div>

            <div class="form-group">
              <div class="inner-addon left-addon">
                <i class="glyphicon material-icons icon" aria-hidden="true">lock</i>
                <input type="password" class="form-control"  @blur="validatePasswordRepeatInput" v-model="credentials.passwordRepeat" placeholder="Neues Passwort wiederholen"/>
                <span class="validation-error" v-show="showPasswordRepeatError">Die Passwörter stimmen nicht überein.</span>
              </div>
            </div>

            <div class="form-group">
              <button type="submit" @click="handleChangePassword" style="width: 100%;" class="btn btn-fill">Passwort Ändern</button>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import Breadcrumb from '@/components/controls/Breadcrumb.vue';
  import { csrf, setNewPassword } from '@/api/auth';
  import { validatePassword } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: 'password-forgotten',
    components: {
      Breadcrumb
    },
    data() {
      return {
       credentials: {
        oldPassword: '',
        password: "",
        passwordRepeat: "",
      },
      showPasswordError: false,
      showOldPasswordError: false,
      showPasswordRepeatError: false,
      loader: false,
    };
  },
  methods: { 
    showLoader,
    hideLoader,
    validatePassword,
    validatePasswordInput(){
      if(!this.validatePassword(this.credentials.password)){
        this.showPasswordError=true;
        return false;
      }else{
        this.showPasswordError=false;
        return true;
      }
    },
    validateOldPasswordInput(){
      if(!this.validatePassword(this.credentials.oldPassword)){
        this.showOldPasswordError=true;
        return false;
      }else{
        this.showOldPasswordError=false;
        return true;
      }
    },
    validatePasswordRepeatInput(){
      if(this.credentials.password != this.credentials.passwordRepeat){
        this.showPasswordRepeatError=true;
        return false;
      }else{
        this.showPasswordRepeatError=false;
        return true;
      }
    },
    handleChangePassword(e) {
      e.preventDefault();
      var oldPasswordValid = this.validateOldPasswordInput();
      var passwordValid = this.validatePasswordInput();
      var passwordRepeatValid = this.validatePasswordRepeatInput();
      if(!passwordValid || !passwordRepeatValid || !oldPasswordValid){
        return;
      }

      this.loader = this.showLoader(this.loader);
      csrf().then(() => {
        setNewPassword(this.credentials).then(() => {
         this.$notify({
          duration: 2500,
          title: 'Passwort geändert!',
          text: 'Ihr Passwort wurde erfolgreich geändert. Sie können sich jetzt mit Ihrem neuen Passwort anmelden.'
        });

         this.$router.push({name:'Profil'});

       })
        .catch(error => {
          var message = error.response.data;
          this.$notify({
            type: 'error',
            duration: 3500,
            title: 'Änderung fehlgeschlagen!',
            text: message
          });
        }).finally(()=>{
         this.loader = this.hideLoader(this.loader);
       });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .change-password {
    position: relative;
    background: #fff;
    padding-top: 110px;
    padding-bottom: 40px;

    .blank{
      display: flex;
      align-items: center;
      height: 60vh;
    }

    .blank-section {
      width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .container-fluid {
      max-width: 1000px;

      #change-password-column{

        @media (max-width: 575px) {
          padding: 0px 50px;
        }

        @media (max-width: 400px) {
          padding: 0px 30px;
        }

      }


      h3.text-info {
        color: #00183E !important;
        margin-bottom: 30px;
      }

      .form-check{
        margin-bottom: 20px;      
      }

      .form-group {
        margin-bottom: 30px;

        .left-addon .glyphicon {
          left: 0px;
        }

        .inner-addon {
          position: relative;
        }

        .inner-addon .glyphicon {
          position: absolute;
          padding: 7px;
          pointer-events: none;
          color: #E30059;
        }

        .left-addon input {
          padding-left: 35px;
        }


      }
    }


  }
</style>
