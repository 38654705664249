<template>
    <div class="page footer-margin">
        <Breadcrumb />

        <div class="page__breadcrumb">
            <div class="container-fluid">
                <div class="page-info">
                    <div class="page-info__item" v-if="collection!=null">
                        <h4>{{collection.name}}</h4>
                    </div>
                    <div class="page-info__item">

                        <button class="btn btn-profile icon-before mr20" @click="showShareCollectionDialog=true" v-if="canShareCollection">
                            <i class="material-icons" aria-hidden="true">share</i>
                            <span>Teilen</span>
                        </button>

                        <button class="btn btn-profile icon-before mr20" @click="showEditCollection=true" v-if="canEditCollection">
                            <i class="material-icons" aria-hidden="true">edit</i>
                            <span>Umbenennen</span>
                        </button>

                        <button class="btn btn-profile icon-before" @click="showDeleteCollectionModal=true" v-if="canDeleteCollection">
                            <i class="material-icons" aria-hidden="true">delete</i>
                            <span>Löschen</span>
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="hide-on-mobile">

                <div class="row" v-if="institutions.length>0 || events.length>0 || attractions.length>0 || kultouren.length>0">
                    <div class="col-12 page-favorites" v-if="events.length>0">
                        <h3 class="title">Angebote & Veranstaltungen</h3>
                        <div class="row">

                            <div class="col-xl-3 col-lg-4 col-md-6 mt20" v-for="(content,index) in events" :key="index">
                                <CardEvent class="info-card" :content="content" @reloadCollection="reloadCollection"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 page-lists" v-if="institutions.length>0">
                        <h3 class="title">Veranstalter & Institutionen</h3>
                        <div class="row">

                            <div class="col-xl-3 col-lg-4 col-md-6 mt20" v-for="(content,index) in institutions" :key="index">
                                <CardInstitution class="info-card" :content="content" :isSmall="true" @reloadCollection="reloadCollection"/>
                            </div>

                        </div>
                    </div>

                    <div class="col-12 page-favorites" v-if="kultouren.length>0">
                        <h3 class="title">KulTouren</h3>
                        <div class="row">

                            <div class="col-xl-3 col-lg-4 col-md-6 mt20" v-for="(content,index) in kultouren" :key="index">
                                <CardKultour class="info-card" :collection="content" @reloadCollection="reloadCollection"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 page-favorites" v-if="attractions.length>0">
                        <h3 class="title">Sehenswürdigkeiten</h3>
                        <div class="row">

                            <div class="col-xl-3 col-lg-4 col-md-6 mt20" v-for="(content,index) in attractions" :key="index">
                                <CardAttraction class="info-card" :content="content" @reloadCollection="reloadCollection"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb30 mt30" v-else>
                    <div class="error-msg">
                        <img style="padding-top: 30px; padding-bottom: 0px;" src="/assets/intro/OnboardingAnker.png" alt="Intro-Img 2"/>
                        <h4>Keine Inhalte vorhanden.</h4>
                        <div class="mt10">Nutze den Anker-Button, um dir Inhalte für später zu merken.</div>
                    </div>
                </div>

            </div>

            <div class="only-on-mobile">
                <div v-if="institutions.length>0 || events.length>0 || attractions.length>0 || kultouren.length>0">
                    <div class="profil-tabs">
                        <div class="tabs-control">
                            <div class="w-50">
                                <div :class="['tabs-control__item', active === SECTIONS.INSTITUTIONS ? 'is-active' : '']" v-if="institutions.length>0" @click="active = SECTIONS.INSTITUTIONS">
                                    <h5>Veranstalter &<br> Institutionen</h5>
                                </div>
                                <div :class="['tabs-control__item', active === SECTIONS.EVENTS ? 'is-active' : '']"  v-if="events.length>0" @click="active = SECTIONS.EVENTS">
                                    <h5>Angebote &<br> Veranstaltungen</h5>
                                </div>
                            </div>
                            <div class="w-50">
                                <div :class="['tabs-control__item', active === SECTIONS.KULTOUREN ? 'is-active' : '']"  v-if="kultouren.length>0" @click="active = SECTIONS.KULTOUREN">
                                    <h5>KulTouren</h5>
                                </div>
                                <div :class="['tabs-control__item', active === SECTIONS.ATTRACTIONS ? 'is-active' : '']"  v-if="attractions.length>0" @click="active = SECTIONS.ATTRACTIONS">
                                    <h5>Sehenswürdigkeiten</h5>
                                </div>
                            </div>
                        </div>
                        <div class="tabs-content">
                            <div :class="['tabs-content__item', active === SECTIONS.INSTITUTIONS ? 'is-active' : '']">
                                <div class="mb20" v-for="(content,index) in institutions" :key="index">
                                    <CardInstitution class="info-card" :content="content" :isSmall="true" @reloadCollection="reloadCollection"/>
                                </div>
                            </div>
                            <div :class="['tabs-content__item', active === SECTIONS.ATTRACTIONS ? 'is-active' : '']">
                                <div class="mb20" v-for="(content,index) in attractions" :key="index">
                                    <CardAttraction class="info-card" :content="content" :isSmall="true" @reloadCollection="reloadCollection"/>
                                </div>
                            </div>
                            <div :class="['tabs-content__item', active === SECTIONS.KULTOUREN ? 'is-active' : '']">
                                <div class="mb20" v-for="(content,index) in kultouren" :key="index">
                                    <CardKultour class="info-card" :collection="content" :isSmall="true" @reloadCollection="reloadCollection"/>
                                </div>
                            </div>
                            <div :class="['tabs-content__item', active === SECTIONS.EVENTS ? 'is-active' : '']" v-if="events.length>0">

                                <div class="mb20" v-for="(content,index) in events" :key="index">
                                    <CardEvent class="info-card" :content="content" @reloadCollection="reloadCollection"></CardEvent>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb30 mt30" v-else>
                    <div class="error-msg">
                        <img style="padding-top: 30px; padding-bottom: 0px;" src="/assets/intro/OnboardingAnker.png" alt="Intro-Img 2"/>
                        <h4>Keine Inhalte vorhanden.</h4>
                        <div class="mt10">Nutze den Anker-Button, um dir Inhalte für später zu merken.</div>
                    </div>
                </div>

            </div>

        </div>

        <edit-collection-modal v-if="this.collection!=null && showEditCollection" :collection="this.collection" @closeModal="closeEditCollectionModal"></edit-collection-modal>
        <delete-collection-modal v-if="this.collection!=null && showDeleteCollectionModal" :collection="this.collection" @closeModal="showDeleteCollectionModal=false"></delete-collection-modal>

        <share-dialog :collection="collection" v-if="collection!=null && showShareCollectionDialog" @closeModal="showShareCollectionDialog=false"></share-dialog>


    </div>
</template>

<script>
    import Resource from '@/api/resource';
    import Breadcrumb from '@/components/controls/Breadcrumb.vue';
    import CardInstitution from '@/components/cards/CardInstitution.vue';
    import CardAttraction from '@/components/cards/CardAttraction.vue';
    import CardKultour from '@/components/cards/CardKultour.vue';
    import CardEvent from '@/components/cards/CardEvent.vue';
    import EditCollectionModal from '@/components/modal/editCollection.vue';
    import DeleteCollectionModal from '@/components/modal/deleteCollection.vue';
    import checkPermission from '@/utils/permission';
    import { showLoader, hideLoader } from '@/utils/helpers';
    import ShareDialog from '@/components/modal/shareCollection/shareDialog.vue';


    const collectionResource = new Resource('collections');

    const SECTIONS = Object.freeze({
        INSTITUTIONS: 'INSTITUTIONEN',
        EVENTS: 'VERANSTALTUNGEN',
        ATTRACTIONS: 'SEHENSWÜRDIGKEITEN',
        KULTOUREN: 'KULTOUREN',
    });

    export default {
        name: 'Collection',
        components: {
            Breadcrumb,
            CardInstitution,
            CardEvent,
            CardKultour,
            CardAttraction,
            ShareDialog,
            DeleteCollectionModal,
            EditCollectionModal
        },
        data() {
            return {
                SECTIONS,
                loader: null,
                collection: null,
                active: SECTIONS.INSTITUTIONS,
                showEditCollection: false,
                showShareCollectionDialog: false,
                showDeleteCollectionModal: false,
            }
        },
        computed: {
          id() {
            return this.$route.params.id;
        },
        canShareCollection(){
            if(this.checkPermission({elem: this.collection ,permission: 'shareCollections'})){
              return true;
          }
          return false;
      },
      canEditCollection(){
        if(this.checkPermission({elem: this.collection ,permission: 'editCollections'})){
          return true;
      }
      return false;
  },
  canDeleteCollection(){
    if(this.checkPermission({elem: this.collection ,permission: 'deleteCollections'})){
      return true;
  }
  return false;
},
user(){
  return this.$store.state.user;
},
kultouren() {
    var kultouren = [];
    if(this.collection!=null && this.collection.contents!=null && this.collection.contents.length>0){
        for(var i = 0; i < this.collection.contents.length; i++){
            var content = this.collection.contents[i];
            if(content.content_type_name=="kultour"){
                kultouren.push(content);
            }
        }
    }
    return kultouren;
},
attractions() {
    var attractions = [];
    if(this.collection!=null && this.collection.contents!=null && this.collection.contents.length>0){
        for(var i = 0; i < this.collection.contents.length; i++){
            var content = this.collection.contents[i];
            if(content.content_type_name=="sehenswrdigkeit"){
                attractions.push(content);
            }
        }
    }
    return attractions;
},
events() {
    var events = [];
    if(this.collection!=null && this.collection.contents!=null && this.collection.contents.length>0){
        for(var i = 0; i < this.collection.contents.length; i++){
            var content = this.collection.contents[i];
            if(content.content_type_name=="angebot"){
                events.push(content);
            }
        }
    }
    return events;
},
institutions(){
    var institutions = [];
    if(this.collection!=null && this.collection.contents!=null && this.collection.contents.length>0){
        for(var i = 0; i < this.collection.contents.length; i++){
            var content = this.collection.contents[i];
            if(content.content_type_name=="institution"){
                institutions.push(content);
            }
        }
    }
    return institutions;
}
},
created() {
  this.getCollection();
},
methods: {
    showLoader,
    hideLoader,
    checkPermission,
    closeEditCollectionModal(){
        this.showEditCollection=false;
        this.getCollection();
    },
    reloadCollection(collection_id){
        if(this.collection.id == collection_id){
            this.getCollection();
        }
    },
    getCollection() {
        this.loader = this.showLoader(this.loader);
        collectionResource.get(this.id)
        .then(response => {
          this.collection = response.data;
      })
        .catch(error => {
            console.log(error);

            this.$notify({
              type: 'error',
              duration: 2500,
              title: 'Keine Berechtigung',
              text: 'Sie haben nicht die benötigten Berechtigungen um diese Liste einzusehen.'
          });

            if(this.user==null){
                this.$router.push({name:'login'});
            }

        })
        .finally(() => {
            this.loader = this.hideLoader(this.loader);
        });
    }
}
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .error-msg{
        text-align:center; 
        width: 500px; 
        margin: 0 auto;
    }

    .page {
        position: relative;
        background: #fff;
        padding-top: 70px;
        padding-bottom: 50px;

        &__breadcrumb {
            margin-bottom: 15px;
            border-radius: 5px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            padding: 35px 0 25px 0;

            @media (max-width:1200px){
                margin-bottom: 10px; 
            }

            @media (max-width: 786px) {
                box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
            }

            .page-info {
                display: flex;
                margin-left: 60px;
                align-items: center;
                justify-content: space-between;

                .btn.btn-profile {
                    width: 220px;

                    @media (max-width: 985px) {
                        width: 40px;
                        height: 40px;
                        padding: 0;
                        border-radius: 100px;
                    }

                    @media (max-width: 500px) {
                        margin-bottom: 5px;
                    }

                    &.mr20 {
                        @media (max-width: 485px) {
                            margin-right:10px!important;
                        }
                    }

                    i.material-icons {
                        left: 20px;
                        @media (max-width: 985px) {
                            position: relative;
                            left: 0;
                        }
                    }

                    span {
                        @media (max-width: 985px) {
                            display: none;
                        }
                    }

                }
            }
        }

        &-favorites,
        &-lists {
            margin-bottom: 20px;

            @media (max-width:1200px){
                margin-bottom: 30px; 
            }

            .title {
                font-size: 22px;
                color: $primary;
                margin-top: 10px;

                @media (max-width:1200px){
                   margin-top: 0px; 
               }

           }

           .title__label {
            font-size: 16px;
            color: $primary;
            margin-top: 20px;
        }

    }

    .info-card{
        margin-right: 5px!important;
        margin-bottom: 10px!important;
    }

    .profil-tabs {
        position: relative;

        .tabs-control {
            display: flex;
            margin-left: -15px;
            margin-right: -15px;
            text-align: center;


            &__item {
                flex: 1;
                padding: 15px 0;
                cursor: pointer;
                height: 50%;
                border: 1px solid $lightGray;
                border-radius: 5px;

                h5{
                    @media(max-width:370px){
                        font-size: 14px;
                    }
                }

                &.is-active {
                    border: 1px solid $primary;

                }
            }
        }

        .tabs-content {
            padding: 20px 0;

            .tabs-content__item {
                display: none;
                flex-direction: column;
                vertical-align: middle;
                max-width: 500px;
                margin: 0 auto;

                &.is-active {
                    display: flex;
                }

                &--single {
                    margin-bottom: 20px;

                    &-head {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .btn-bottom {
        display: block;

        @media (max-width: 786px) {
            margin: 0 auto;
            background: #fff;
            color: $secondary;
        }

    }

    @media (max-width: 786px) {
        padding-top: 60px;
    }
}
</style>
