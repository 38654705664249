<template>
    <div class="filter-button only-on-mobile">
        <button class="btn btn-2 btn-filter" @click="isVisible = true">Filter<i class="material-icons" aria-hidden="true">keyboard_arrow_up</i></button>
        <div v-if="isVisible" class="filter-content">
            <div class="filter-content__btn" @click="isVisible = false">
                <button class="btn btn-link btn-icon">
                    <span>Filter</span>
                    <i class="material-icons" aria-hidden="true">keyboard_arrow_down</i>
                </button>
            </div>
            <div class="filter-content__section">
                <h4>Kategorien</h4>
                <label v-for="cat in categories" :key="cat.id" :class="['filter-content__select', query.selectedCategory === cat.value ? 'selected': '']" @click="categoryChanges(cat.value)">{{ cat.label }}</label>
            </div>
            <div class="filter-content__section">
                <h4>Tags</h4>
                <label v-for="tag in tags" :key="tag.id" :class="['filter-content__select', isSelected(tag.value) ? 'selected': '']" @click="tagChanges(tag.value)">{{ tag.label }}</label>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "FilterBtn",
    data() {
        return {
            isVisible: false,
            query:{
                selectedTags: null,
                selectedKeyword: null,
                selectedCategory: null,
            },
        }
    },
    computed:{
        categories(){
            var categories = JSON.parse(JSON.stringify(this.$store.getters.getCategories));
            /*remove the first element - that element is the default element with the value null*/
            categories.splice(0,1);
            return categories;
        },
        tags(){
            var tags = JSON.parse(JSON.stringify(this.$store.getters.getTags));
            /*remove the first element - that element is the default element with the value null*/
            tags.splice(0,1);
            return tags;
        },
    },
    methods:{
       queryChanges(){
        this.query.page=1;
        this.$emit('reload',this.query);
    },
    isSelected(tagValue){
        return this.query.selectedTags!==null&&this.query.selectedTags.includes(tagValue)?true:false;
    },
    tagChanges(tagValue){
        if(this.query.selectedTags == tagValue){
            this.query.selectedTags = null;
        }
        else{
            this.query.selectedTags = tagValue; 
        }
        this.queryChanges();
    },
    categoryChanges(categoryValue){
        if(this.query.selectedCategory == categoryValue){
            this.query.selectedCategory = null;
        }
        else{
            this.query.selectedCategory = categoryValue; 
        }
        this.queryChanges();
    }
}
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.filter-button {

    .btn-filter {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 40px;
        border-radius: 0;
        background: #7BD1F4;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: 1001;
        padding: 0 0 8px 0;
        
        .material-icons {
            position: relative;
            top: 8px;
        }
    }

    .filter-content {
        position: fixed;
        background: #fff;
        max-width: 96%;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        box-shadow: 0px 0px 15px 0px rgba($color: $primary, $alpha: 0.2);
        bottom: 0;
        padding: 15px;
        margin-left: 2%;
        z-index: 2111;
        max-height: 90vh;
        overflow: auto;

        &__btn {
            .btn.btn-link{
                display: flex;
                align-items: center;
                vertical-align: middle;
                justify-content: center;
                margin: 10px auto;
                margin-top: 5px;
                text-decoration:none!important;
                font-size: 14px;
                color: $darkGray;

                @media (max-width: 400px){ 
                    margin: 0 auto;
                }

                &:hover{
                    text-decoration: none;
                }
            }
        }

        &__section {
            margin-bottom: 20px;
            
            @media (max-width: 400px){ 
                margin-bottom: 10px;
            } 

            h4 {
                margin-bottom: 10px;

                @media (max-width: 400px){ 
                    font-size: 14px;
                    margin-bottom: 5px;
                } 
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__select {
            display: inline-block;
            padding: 2px 10px;
            border-radius: 20px;
            margin: 3px;
            color: $infoColor;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
            border: 2px solid $infoColor;
            font-size: 12px;

            @media (max-width: 400px){ 
                font-size: 10px;
                padding: 0px 6px;
            } 

            &.selected {
                border: none;
                padding:4px 12px;
                background: linear-gradient(180deg, #7BD1F4 0%, #009EE0 100%);
                color: #fff;
            }
        }
    }
}
</style>
