import request from '@/utils/request';

export function getAppCollections(id) {
	return request({
		url: 'apps/'+id+'/collections',
		method: 'get',
	});
}

export function getCollectionsOfUser(user){
	return request({
		url: '/user/'+ user.id +'/collections',
		method: 'get',
	});
}

export function addNewCollection(collection,content = null){
	var data = {'content':content,'collection':collection};

	return request({
		url: '/collection/add',
		method: 'post',
		data: data,
	});
}

export function addContentToCollection(content, collection) {
	var data = {'content':content,'collection':collection};

	return request({
		url: '/collection/add-content-to-collection',
		method: 'post',
		data: data,
	});
}

export function removeContentFromCollection(content_id, collection_id) {
	var data = {'content_id':content_id,'collection_id':collection_id};

	return request({
		url: '/collection/remove-content-from-collection',
		method: 'post',
		data: data,
	});
}

export function getCollectionRoles(){
	return request({
		url: '/collection/roles',
		method: 'get',
	});
}

export function getUsersForCollection(collection_id){
	return request({
		url: '/collection/'+collection_id+'/users',
		method: 'get',
	});
}

export function shareCollection(collection_id, email, role_id, appUrl, appId){
	var data = {'collection_id':collection_id,'email':email,'role_id':role_id, 'appUrl': appUrl, 'appId': appId};

	return request({
		url: '/collection/share',
		method: 'post',
		data: data,
	});
}

export function changeShareStatus(share_id, role_id){
	var data = {'utc_id':share_id,'role_id':role_id};

	return request({
		url: '/collection/change-share',
		method: 'post',
		data: data,
	});
}

export function removeContentFromCollectionByID(ctc_id){
	var data = {'ctc_id':ctc_id};

	return request({
		url: '/collection/remove-content',
		method: 'post',
		data: data,
	});
}

export function handleCollectionInvitation(token){
	return request({
		url: '/collection/invitation/'+token,
		method: 'get',
	});
}