<template>

  <the-modal @closeModal="closeModal" :isBig=true :showHeader=true>

    <template v-slot:header>
      <div v-if="user==null">
        <h5>Kultur für später merken</h5>
      </div>
      <div v-else>
        <h5>Merken</h5>
      </div>
    </template>

    <template v-slot:body>

      <div v-if="user==null">
        <span style="text-align: center;">Erstelle jetzt ganz einfach ein Profil und erhalte Kultur-Empfehlungen, lege Merklisten an und vieles mehr!</span>
        <img style="width: 100%; padding: 20px 25px;" src="/assets/intro/OnboardingAnker.png" alt="Intro-Img 2"/>
      </div>
      <div v-else>
        <div class="text-center">
          <span class="description-text">Speichere Orte und Veranstaltungen in deinem Profil, damit du sie schnell wiederfindest:</span>

          <button class="btn btn-fill icon-before mt10" style="width:100%;" v-if="!isInFavorits" @click="addToFavoriten">
            <i class="material-icons" aria-hidden="true">star</i>
            <span>Als Favorit speichern</span>
          </button>

          <button class="btn btn-fill icon-before mt10" style="width:100%;" v-else @click="removeFromFavoriten">
            <i class="material-icons" aria-hidden="true">delete</i>
            <span>Aus Favoriten entfernen</span>
          </button>

        </div>

        <div class="mt20 text-center" v-if="hasCollections">
          <span class="description-text">Erstelle deine eigenen Themen-Listen, um Favoriten zu ordnen:</span>

          <button class="btn btn-fill icon-before mt10" style="width:100%;" @click="toggleExpandableElement('list')">
            <i class="material-icons" aria-hidden="true">list</i>
            <span>Zu Liste hinzufügen</span>
          </button>
        </div>

        <div>
          <div class="my-collections mt20" v-show="showList">

            <list-group 
            style="margin-bottom: 20px;" 
            v-if="userCollections.length > 0"
            @addToList="beforeAddContentToCollection" 
            @removeFromList="beforeRemoveContentFromCollection" 
            :content="content" 
            :title="'Meine Listen'"
            :collections="userCollections" 
            :expanded="true"/>

            <list-group 
            style="margin-bottom: 20px;"
            v-if="sharedCollections.length > 0"
            @addToList="beforeAddContentToCollection"
            @removeFromList="beforeRemoveContentFromCollection" 
            :content="content"  
            :title="'Mit mir geteilte Listen'" 
            :collections="sharedCollections" 
            :expanded="false"/>

            <list-group 
            v-if="institutionCollections.length > 0"
            style="margin-bottom: 20px;" 
            @addToList="beforeAddContentToCollection" 
            @removeFromList="beforeRemoveContentFromCollection" 
            :content="content" 
            :title="'Listen meiner Institution'"
            :collections="institutionCollections"
            :expanded="false"/>

            <div v-if="appsCollections.length>0">
              <list-group 
              v-for="app in appsCollections" :key="app.name"
              @addToList="beforeAddContentToCollection" 
              @removeFromList="beforeRemoveContentFromCollection" 
              :content="content" 
              :title="app.name + ' Listen'" 
              :collections="app.collections" 
              :expanded="false"
              style="margin-bottom: 20px;"/>
            </div>

          </div>

        </div>
      </div>

    </template>
    <template v-slot:footer>

      <div v-if="user==null">
        <center>
          <router-link :to="{ name: 'login' }" class="btn btn-fill btn-focus-design" style="width:100%;">Anmelden</router-link><br>
          <router-link :to="{ name: 'register' }" class="btn mt20 btn-focus-design" style="width:100%;">Registrieren</router-link>
        </center>
      </div>
      <div v-else>
        <div class="btn" @click="closeModal">
          Fertig
        </div>
      </div>

    </template>


  </the-modal>


</template>

<script>
  import TheModal from '@/components/modal/show.vue';
  import { addContentToCollection, removeContentFromCollection } from '@/api/collection';
  import ListGroup from './listgroup';

  export default {
    name: 'CollectionDialog',
    components: { ListGroup, TheModal },
    props: {
      content: {
        type: Object,
        default: null,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
        showList: false,
        showThisDialog: this.showDialog,
        showAddNewList: false,
      };
    },
    mounted() {
    },
    computed: {
      user(){
        return this.$store.state.user;
      },
      hasCollections(){
        if(this.userCollections.length>0||this.sharedCollections.length>0||this.institutionCollections.length>0||this.appsCollections.length>0){
          return true;
        }
        return false;
      },
      collections(){
        return this.$store.getters.getCollections;
      },
      institutionCollections(){
        if(this.collections != null && Object.prototype.hasOwnProperty.call(this.collections,'institution')){
          return this.collections.institution;
        }
        return [];
      },
      userCollections(){
        if(this.collections != null && Object.prototype.hasOwnProperty.call(this.collections,'own')){
          return this.collections.own;
        }
        return [];
      },
      sharedCollections(){
        if(this.collections != null && Object.prototype.hasOwnProperty.call(this.collections,'shared')){
          return this.collections.shared;
        }
        return [];
      },
      appsCollections(){
        if(this.collections != null && Object.prototype.hasOwnProperty.call(this.collections,'apps')){
          return this.collections.apps;
        }
        return [];
      },
      theFavoritenCollection(){
        /*check if the user has any personal collections*/
        if(this.userCollections.length>0){
          /*let's look in this set of collections for one with the title "Favoriten"*/
          for(var i = 0; i < this.userCollections.length; i++){
            if(this.userCollections[i].name == "Favoriten"){
              return this.userCollections[i];
            }
          }
        }
        return null;
      },
      isInFavorits(){
        if(this.theFavoritenCollection != null && this.isContentInCollection(this.theFavoritenCollection)){
          return true; 
        }
        return false;
      },
    },
    methods: {
      closeThisDialog(){
        this.$emit('closeThisDialog');
      },
      isContentInCollection(collection){
        for(var i=0; i<collection.contents.length; i++){
          if(collection.contents[i].id == this.content.id){
            return true;
          }
        }
        return false;
      },
      addToFavoriten(){
        /*add a certain content to Favoriten*/
        if(this.theFavoritenCollection != null){
          this.beforeAddContentToCollection(this.theFavoritenCollection);
        }
        else{
          /*if there is no FavoritenCollection*/
          this.addContentToCollection('Favoriten')
        }
      },
      beforeAddContentToCollection(collection){
        if(this.isContentInCollection(collection)){
          this.$confirm('Der Inhalt ist bereits in dieser Liste gespeichert. Möchtest du diesen Inhalt erneut hinzufügen?', {
            confirmButtonText: 'Hinzufügen',
            cancelButtonText: 'Abbrechen'
          }).then(() => {
            /*user said ok*/
            this.addContentToCollection(collection);
          }).catch(() => {
           /*do nada*/
         });
        }
        else{
          this.addContentToCollection(collection);
        }
      },
      addContentToCollection(collection){

        this.loading = true;

        addContentToCollection(this.content,collection)
        .then(() => {
          this.content.in_collection = true;
          this.$notify({
            duration: 2500,
            title: 'Inhalt gespeichert!',
            text: 'Der Inhalt wurde erfolgreich zu der Merkliste hinzugefügt.'
          });

        })
        .catch(error => {

          console.log(error);

          this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Fehler!',
            text: 'Der Inhalt konnte nicht gespeichert werden.'
          });

        })
        .finally(() => {
          this.$store.dispatch('loadUserCollections');
          this.$store.dispatch('loadUser');
          this.loading = false;
        });

      },
      removeFromFavoriten(){
        this.removeContentFromCollection(this.theFavoritenCollection);
      },
      beforeRemoveContentFromCollection(collection){
        this.removeContentFromCollection(collection);
      },
      removeContentFromCollection(collection){
        this.loading = true;
        removeContentFromCollection(this.content.id,collection.id)
        .then(() => {
          this.content.in_collection = false;

          this.$notify({
            duration: 2500,
            title: 'Inhalt entfernt!',
            text: 'Der Inhalt wurde erfolgreich aus der Merkliste entfernt.'
          });

          this.$emit('reloadCollection',collection.id);

        })
        .catch(error => {

          console.log(error);

          this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Fehler!',
            text: 'Der Inhalt konnte nicht aus der Merkliste entfernt werden.'
          });

        })
        .finally(() => {
          this.$store.dispatch('loadUserCollections');
          this.$store.dispatch('loadUser');
          this.loading = false;
        });

      },
      toggleExpandableElement(element){
        if(element == "list"){
          this.showList = this.showList==true?false:true;
          /*if the list is shown, the add new list form should be hidden*/
          if(this.showList == true){
            this.showAddNewList = false;
          }
        }
        if(element == "add-list"){
          this.showAddNewList = this.showAddNewList==true?false:true;
          /*if the list is shown, the add new list form should be hidden*/
          if(this.showAddNewList == true){
            this.showList=false;
          }
        }
      },
      closeModal(){
        this.$emit("closeModal");
      },
    },
  };

</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .add-to-collection-dialog .el-dialog__title{
    color: #36a3f7;
  }

  .add-to-collection-dialog .el-dialog__body{
    padding: 20px 20px;
  }

  .add-to-collection-dialog .el-dialog__body .description-text{
    display: block;
    word-break: normal;
    line-height: 20px;
    margin-bottom: 15px!important;
  }

  .add-to-collection-dialog .el-dialog__body .el-button{
    margin-top: 10px;
    margin: 0 auto;
    display: block;
    width: 250px;
  }

  .add-to-collection-dialog .el-dialog__footer {
    text-align: center;
  }

  .add-to-collection-dialog .el-dialog__body .my-collections{
    padding: 5px 0px;
    margin: 20px 30px;
  }

  .my-collections.add-list{
    margin-bottom: 0px!important;
  }

  .is-already-in-favoriten{
    background-color: fuchsia!important;
  }

</style>
