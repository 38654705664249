<template>
    <a :href="collectionUrl" class="card card-collections" :class="[isCurrentlyActiveCard, isSmall? 'small':'']" :style="{width: width, marginRight: marginRight, marginBottom: marginBottom}" @click="navigateToView" v-if="collection!=null">

        <div class="card-img" :class="checkSafari?'safari':''" :style="getBackgroundImage()">
        </div>
        <div class="card-detail">
            <div class="row mb10">
                <h6 class="col-6 collection-name">{{ collection.name }}</h6>
                <span class="col-6" style="text-align: right;">{{ numberOfContents }} Inhalte</span>
            </div>
        </div>
    </a>
</template>

<script>
    import { parseTravelTime, getPlaceholderImage, json_decode } from '@/utils/helpers';

    export default {
        name: "CardCollection",
        props: {
            collection: {
                type: Object,
                default: null,
                required: true
            },
            isSmall: {
                type: Boolean,
                default: false
            },
            width: {
                type: String,
                default: '100%'
            },
            marginRight: {
                type: String,
                default: '15px'
            },
            marginBottom: {
                type: String,
                default: '30px'
            },
            disableLink: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            imageHeight: function() {
                return this.isSmall ? '105px' : '210px';
            },
            checkSafari(){
                if(this.isSafari&&this.hasPath("footPath")){
                    return true;
                }
                return false;
            },
            numberOfContents(){
                if(this.collection!=null && Object.prototype.hasOwnProperty.call(this.collection,'contents')){
                  return this.collection.contents.length;
              }
              return 0;
          },
          collectionImage: function() {
            if(this.collection!=null && Object.prototype.hasOwnProperty.call(this.collection,'image') && this.collection.image!=null){
                var logo = this.collection.image;
                if(logo!=null){
                    logo = json_decode(logo);
                    if(typeof logo === "object"){
                        return this.$backendUrl+logo.path;
                    }
                    else { 
                        logo = logo.charAt(0)!="/"?"/"+logo:logo;   
                        return this.$backendUrl+logo;
                    }
                }
            }
            return this.getPlaceholderImage();
        },
        metaObject(){
            if(this.collection!=null && Object.prototype.hasOwnProperty.call(this.collection,'meta')){
                return JSON.parse(this.collection.meta);
            }
            return null;
        },
        isCurrentlyActiveClass: function() {
            if (this.isCurrentlyActive){
                return 'highlight';
            }
            return '';
        },
        isCurrentlyActiveCard(){
            if(this.collection.highlight == true){
                return "highlight";
            }
            return 'not-highlighted';
        },
        isSafari(){
            var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            if(iOS){
                return true;
            }
            else{
                return false;
            }
        },
        collectionUrl(){
            var props = "";
            if(this.collection.collection_type=='app'){
                props = this.$router.resolve({ 
                  name: 'Kulturspur',
                  params: { id: this.collection.id },
              });
            }
            else{
                props = this.$router.resolve({ 
                  name: 'Collection',
                  params: { id: this.collection.id },
              });
            }

            return props.href;
        },
    },
    methods: {
        parseTravelTime,
        getPlaceholderImage,
        json_decode,
        hasPath(path){
            if(path!=null && Object.prototype.hasOwnProperty.call(path,'paths') && path.paths.length>0){
                return true;
            }
            return false;
        },
        getBackgroundImage(){
            return 'background-image: url("' + this.collectionImage + '"); max-height:'+ this.imageHeight;
        },
        navigateToView(event){
            if(this.disableLink){
                this.$emit('highlight',{sender:'collection-card', collection: this.collection});
                event.preventDefault()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .card.small .card-detail__features li{
        display: block;

        span{
            display: block;
            margin: 0 auto;
            text-align:center;
        }
        img{
            height: 18px;
            display: block;
            margin: 0 auto;
        }
    }

    .card{
        position: relative;
        border-radius: 5px;
        border-bottom-left-radius: 30px;
        border: none;
        min-width: 264px;
        margin-right: 15px;
        box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            color: $primary;
            box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.35);
        }

        &.highlight{
            border: 3px solid $secondary;
        }


        .collection-name {
            font-size: 14px!important;
        }

        .card-img {
            position: relative;
            overflow: hidden;
            min-height: 100px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            @media (max-width: 600px){ 
                min-height: 80px;
            }

            &.safari{
                @media (max-width: 600px) and (max-height: 600px){ 
                    display:none;
                }
            }

            img {
                width: 100%;
                overflow: hidden;
            }
        }

        .card-detail {
            padding: 20px;

            @media (max-width: 600px){ 
                padding: 10px;
            }

            .title {
                font-size: 14px;
                margin-bottom: 10px;
            }

            &__features {
                display: flex;

                li {
                    flex: 1;
                    display: inline-flex;
                    vertical-align: middle;
                    align-items: center;

                    i, img {
                        margin-right: 10px;
                    }

                    img {
                        display: inline-block;
                        max-height: 24px;
                    }

                    &.highlight {
                        color: $success !important;

                        span {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

</style>
