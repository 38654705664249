<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-8 col-xl-6 copy-right-inline">
                    <div class="copy-right">
                        <h5>Kiel.KulturKompass</h5>
                        <p>&copy; {{currentYear}} Landeshauptstadt Kiel</p>
                    </div>
                    <a target="_blank" href="https://apps.apple.com/de/app/kiel-kulturkompass/id1613953101" class="store-link" title="Link zur App im Apple-Store"><img src="/assets/appstore.png" class="store-image" alt="Apple-Store Link" aria-hidden="true"></a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=multilang.kkk" class="store-link" title="Link zur App im Google Play-Store"><img src="/assets/playstore.png" class="store-image" alt="Google-Store Link" aria-hidden="true"></a>
                </div>
                <div class="col-12 col-lg-4 col-xl-6 footer-link-area">
                    <ul class="footer-menu">
                        <li><router-link to="/impressum" title="Zu dem Impressum">Impressum</router-link></li>
                        <li><router-link to="/datenschutz" title="Zu der Datenschutzerklärung">Datenschutz</router-link></li>
                        <li><router-link to="/barrierefreiheit" title="Zu der Barrierefreiheitserklärung">Barrierefreiheitserklärung</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'Footer',
        computed: {
            currentYear(){
                return new Date().getFullYear()
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    footer {
        position: fixed;
        width: 100%;
        padding: 50px 0;
        background: $primary;
        color: #fff;
        z-index: -99;
        bottom: 0;

        h5 {
            color: #fff;

            @media (max-width: 786px) {
                display: inline-block;
                margin-right: 10px;
            }
        }

        p {
            @media (max-width: 786px) {
                /*display: inline-block;*/
            }
        }

        .copy-right-inline{
            display: flex;
            justify-content: space-between;

            @media (max-width: 555px) {
                display: block;
                margin-top: 0px;
            }

            .copy-right{
                @media (max-width: 555px) {
                    display: block;
                    text-align: center;
                }
            }

            .store-link{
                @media (max-width: 991px) {
                    display: none;
                }

                .store-image {
                    max-width: 110px;
                }
            }

        }

        .footer-link-area{
            @media (max-width: 991px) {
                display: flex;
                justify-content: center;
                margin-top: 20px;
            }

            @media (max-width: 786px) {
                margin-top: 10px;
            }

            @media (max-width: 555px) {
                margin-top: 0px;
            }
        }

        .footer-menu {
            float: right;

            li {
                display: inline-flex;
                margin-left: 40px;

                a { 
                    color: #fff;
                    font-size: 14px;
                    font-weight: 700;
                }

                @media (max-width: 400px) {
                    margin-left: 15px;
                }

                &:first-child {
                    margin-left: 0;
                }
            }

            @media (max-width: 786px) {
                float: left;
                margin-top: 10px;
                text-align: center;
            }
        }

        @media (max-width: 991px) {
            padding: 30px 0;
        }
    }

    .container{

     @media (max-width: 786px) {
        max-width: 100%;
    }

}
</style>
