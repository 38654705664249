<template>
    <div class="system footer-margin">
        <Breadcrumb />

        <div class="container footer-margin" v-html="body">

        </div>
    </div>
</template>

<script>
    import Resource from '@/api/resource';
    import Breadcrumb from '@/components/controls/Breadcrumb.vue';
    import { getFieldValues, showLoader, hideLoader } from '@/utils/helpers';
    
    const contentResource = new Resource('contents');

    export default {
        name: 'SystemContentView',
        components: {
            Breadcrumb,
        },
        data() {
            return {
                loader: null,
                content: null,
            }
        },
        watch: {
            routeName: function () {
                this.loadContent();
            }
        },
        computed: {
            routeName() {
                return this.$route.name;
            },
            title(){
                if(this.content!=null){
                    return this.content.title;
                }
                return "";
            },
            body(){
                var desc = getFieldValues(this.content,'body');
                return desc!=null?desc:'';
            },
        },
        created() {
          this.loadContent();
      },
      methods: {
        showLoader, 
        hideLoader,
        loadContent(){
            var id = this.getContentIdFromRouteName();
            if(id!=null){
                this.getContent(id);
            }
            window.scrollTo(0,0);
        },
        getContentIdFromRouteName(){
            var id = null;
            switch(this.routeName){
                case "datenschutz":
                id = this.$datenschutzContentId;
                break;
                case "impressum":
                id = this.$impressumContentId;
                break;
                case "barrierefreiheit":
                id = this.$bfeContentId;
                break;
            }
            return id;
        },
        getContent(id) {
            this.loader = this.showLoader(this.loader);
            contentResource.get(id)
            .then(response => {
              this.content = response.data;
          })
            .finally(() => {
              this.loader = this.hideLoader(this.loader);
          });
        },
    },
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .system{
        position: relative;
        background: #fff;
        padding-top: 135px;
        padding-bottom: 20px;
    }
    
</style>
