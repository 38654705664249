<template>
    <div class="similar-events-box profile-events mt30" v-if="dataSet.data!=null && dataSet.total>0">
        <div class="col-12 pl-0">
            <h4 class="title">Das könnte dir auch gefallen</h4>
        </div>
        <div class="scroll-container">
            <CardEvent
            v-for="event in dataSet.data"
            :key="event.id"
            :content="event"
            :width="'200px'"
            :margin="'10px'"/>
        </div>
    </div>
</template>
<script>
import CardEvent from '@/components/cards/CardEvent.vue';

export default {
    name: "similarEvents",
    components: {
        CardEvent,
    },
    props: {
        dataSet: {
            type: Object,
            required: true,
            default: null,
        }
    },
    data(){
        return{

        }
    },
}
</script>

<style lang="scss" >

.similar-events-box .card-event .card-heading{
    border-bottom-right-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
}

.similar-events-box .scroll-container{
    margin-left: -15px;

    @media (max-width: 992px) {
        flex-wrap: nowrap;
    }
}

.similar-events-box .card-event{
    max-width: 300px;
    min-width: 200px;
    width: 31%;
}

.similar-events-box .card-event:nth-child(3n) {
    margin-right: 0!important;
}

</style>
