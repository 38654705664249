import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import VueAxios from 'vue-axios'
import store from './store';
import axios from 'axios';
import VueScrollTo from 'vue-scrollto';
import Loading from 'vue-loading-overlay';
import VueRouterBackButton from 'vue-router-back-button'
import VueClipboard from 'vue-clipboard2'
import Notifications from 'vue-notification'

Vue.config.productionTip = false

Vue.use(VueScrollTo);
Vue.use(VueClipboard);
Vue.use(Vuex);
Vue.use(VueRouterBackButton, { router });
Vue.use(VueAxios, axios);
Vue.use(VueScrollTo);
Vue.use(Notifications);

/*config*/
/*id of the current app*/
Vue.prototype.$appId= '3';
Vue.prototype.$institutionId= '1';
Vue.prototype.$offerId= '2';
Vue.prototype.$kultourId= '12';
Vue.prototype.$backendUrl = "https://bildungsangebote-kiel.de";
Vue.prototype.$backendApiUrl = "https://bildungsangebote-kiel.de/api/";
Vue.prototype.$impressumContentId = "232159";
Vue.prototype.$datenschutzContentId = "232161";
Vue.prototype.$bfeContentId = "240366";

import './scss/style.scss';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'material-icons/iconfont/material-icons.css';

/*Initialisieren von Loading*/
Vue.use(Loading, {
  color: 'rgb(148, 192, 31)',
  loader: 'dots',
  width: 140,
  FullPage: true
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
