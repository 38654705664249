<template>
  <div class="password-forgotten footer-margin">
    <Breadcrumb />

    <div class="container-fluid blank">
      <div id="password-forgotten-row" class="row justify-content-center align-items-center blank-section">
        <div id="password-forgotten-column">
          <div id="password-forgotten-box" class="col-12">

            <h3 class="text-center text-info">Passwort ändern</h3>

            <div class="form-group text-center">
              <span class="text">Passwort vergessen? - kein Problem. Einfach ein neues Passwort festlegen und direkt loslegen!</span>
            </div>

            <div class="form-group mb-2">
              <div class="inner-addon left-addon">
                <i class="glyphicon material-icons icon" aria-hidden="true">lock</i>
                <input type="password" class="form-control"  @blur="validatePasswordInput" v-model="credentials.password" placeholder="Passwort"/>
              </div>
              <span class="validation-error" v-show="showPasswordError">Geben Sie ein Passwort ein. Beachten Sie dabei die unten genannten Anforderungen.</span>
            </div>

            <div class="mb-2 pw-req">Mindestens 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl</div>

            <div class="form-group">
              <div class="inner-addon left-addon">
                <i class="glyphicon material-icons icon" aria-hidden="true">lock</i>
                <input type="password" class="form-control"  @blur="validatePasswordRepeatInput" v-model="credentials.passwordRepeat" placeholder="Passwort wiederholen"/>
                <span class="validation-error" v-show="showPasswordRepeatError">Die Passwörter stimmen nicht überein.</span>
              </div>
            </div>

            <div class="form-group">
              <button @click="handleReset" style="width: 100%;" class="btn btn-fill btn-focus-design">Passwort Ändern</button>
            </div>

            <div class="login-row">
              <div class="text-center btn-focus-design">Schon dabei?</div>
              <router-link class="btn mt20" style="width: 100%;" :to="{ name: 'login'}">
                Anmelden
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import Breadcrumb from '@/components/controls/Breadcrumb.vue';
  import { csrf, resetPassword } from '@/api/auth';
  import { validatePassword } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: 'password-forgotten',
    components: {
      Breadcrumb
    },
    data() {
      return {
       credentials: {
        token: '',
        password: "",
        passwordRepeat: "",
      },
      showPasswordError: false,
      showPasswordRepeatError: false,
      loader: false,
    };
  },
  mounted() {
    if(this.$route.params.token){
      //console.log(this.$route.params.token);
      this.credentials.token = this.$route.params.token;
    }
  },
  methods: { 
    showLoader,
    hideLoader,
    validatePassword,
    validatePasswordInput(){
      if(!this.validatePassword(this.credentials.password)){
        this.showPasswordError=true;
        return false;
      }else{
        this.showPasswordError=false;
        return true;
      }
    },
    validatePasswordRepeatInput(){
      if(this.credentials.password != this.credentials.passwordRepeat){
        this.showPasswordRepeatError=true;
        return false;
      }else{
        this.showPasswordRepeatError=false;
        return true;
      }
    },
    handleReset(e) {
      e.preventDefault();
      var passwordValid = this.validatePasswordInput();
      var passwordRepeatValid = this.validatePasswordRepeatInput();
      if(!passwordValid || !passwordRepeatValid){
        return;
      }

      this.loader = this.showLoader(this.loader);
      csrf().then(() => {
        resetPassword(this.credentials).then(() => {
         this.$notify({
          duration: 2500,
          title: 'Passwort zurückgesetzt!',
          text: 'Das Passwort wurde erfolgreich zurückgesetzt. Sie können sich jetzt mit Ihrem neuen Passwort anmelden!'
        });

         this.$router.push({name:'login'});

       })
        .catch(() => {
         this.$notify({
          type: 'error',
          duration: 2500,
          title: 'Registrierung fehlgeschlagen!',
          text: 'Die Registrierung ist fehlgeschlagen. Bitte probieren Sie es zu einem späteren Zeitpunk noch einmal!'
        });
       }).finally(()=>{
         this.loader = this.hideLoader(this.loader);
       });
     });
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .password-forgotten {
    position: relative;
    background: #fff;
    padding-top: 110px;
    padding-bottom: 40px;

    .blank{
      display: flex;
      align-items: center;
      height: 60vh;
    }

    .blank-section {
      width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .container-fluid {
      max-width: 1000px;

      #password-forgotten-column{

        @media (max-width: 575px) {
          padding: 0px 50px;
        }

        @media (max-width: 400px) {
          padding: 0px 30px;
        }

      }


      h3.text-info {
        color: #00183E !important;
        margin-bottom: 30px;
      }

      .form-check{
        margin-bottom: 20px;      
      }

      .form-group {
        margin-bottom: 30px;

        .left-addon .glyphicon {
          left: 0px;
        }

        .inner-addon {
          position: relative;
        }

        .inner-addon .glyphicon {
          position: absolute;
          padding: 7px;
          pointer-events: none;
          color: #E30059;
        }

        .left-addon input {
          padding-left: 35px;
        }


      }
    }


  }
</style>
