<template>
    <div class="banner">
        <div class="banner-content">
            <div class="container banner-container">
                <div class="row">
                    <div class="text-container col-12 col-lg-7 col-xl-5">
                        <h1 class="banner-text"><span class="banner-text-bold">Kultur</span> für Kiel</h1>
                        <div class="banner-text-description">Der Kiel.KulturKompass ist der digitale Allrounder zum Thema Kultur in Kiel. 
                            Er ist ein zentraler Informationspunkt mit praktischen Zusatzfunktionen wie persönlichen Merklisten, einem Veranstaltungskalender und KulTouren durch die Kieler Stadtteile.
                        </div>
                        <SearchBar @searchWordChanged="searchWordChanged" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SearchBar from '@/components/SearchBar.vue';

    export default {
        name: "Banner",
        components: {
            SearchBar,
        },
        data() {
            return {}
        },
        methods: {
            searchWordChanged(word){
                this.$router.push({ name: 'Suche', query: { keyword: word } })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .banner {
        padding-top: 150px;
        text-align: center;
        position: relative;
        margin-bottom: 50px;
        padding-left: 60px;

        @media(max-width: 991px){
            margin-bottom: 30px;
            padding-top: 100px;
            padding-left: 0;
        }
    }

    .banner-content {
        text-align: left;

        .banner-text {
            font-size: 72px;
            font-family: elliot-regular;
            font-weight: 300;

            @media(max-width:1255px){
                font-size: 68px;
            }

            @media(max-width: 991px){
                font-size: 64px;
            }

            @media(max-width: 460px){
                font-size: 54px;
            }

            @media(max-width: 381px){
                font-size: 44px;
            }
            

            &-bold {
                font-family: elliot-bold;
                font-weight: 500;
            }
        }

        .banner-text-description {
            font-size: 20px;
            line-height: 32px;

            @media(max-width: 1200px){
                font-size: 18px;
            }

            @media(max-width: 1100px){
                font-size: 17px;
            }

            @media(max-width: 991px){
                display: none;
            }
        }
    }

    .banner-container {
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
    }

    .text-container {
        @media(max-width: 991px){
            text-align: center;
        }
    }

    .footer-fix {

    }

</style>
