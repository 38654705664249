<template>
    <div class="card">
        <router-link :to="{ name: content.slug }">
            <div class="card-body">
                <div class="card-title">
                    <img :src="content.icon" alt="icon">
                    <h4>{{ content.title }}</h4>
                </div>
                <p>{{ content.text }}</p>

                <img src="/assets/icons/right-arrow.png" alt="Click Me">
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "CardText",
        props: {
            content: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.card{
    position: relative;
    border-radius: 5px;
    border-bottom-left-radius: 30px;
    background: #fff;
    margin-top: 30px;
    border: none;
    box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);

    a:hover{
        color:inherit;
    }

    &-body{
        padding: 40px;

        .card-title {
            display: inline-flex;
            vertical-align: middle;
            align-items: center;

            h4 {
                line-height: 20px;
                position: relative;
                top: 3px;
                margin-left: 15px;
            }
        }

        p {
            line-height: 24px;
            font-size: 14px;
            margin: 30px 0 50px;
        }

        @media (max-width: 500px) {
            padding: 20px;
        }
        
    }

    &:first-child {
        margin-top: 0;
        
        @media (max-width: 991px) {
            margin-top: 30px
        }
    }
}

// .card:first-child{


//     @media (min-width: 768px) {
//         margin-top: ;
//     }
// }

</style>
