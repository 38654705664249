<template>

  <the-modal @closeModal="closeModal">
    <template v-slot:header>
      <h5>Liste löschen</h5>
    </template>
    <template v-slot:body>
     Möchten Sie diese Liste wirklich endgültig löschen?
   </template>
   <template v-slot:footer>
    <div class="btn btn-fill" @click="deleteCollection">
      Liste löschen
    </div>
  </template>
</the-modal>

</template>
<script>
import TheModal from '@/components/modal/show.vue';
import Resource from '@/api/resource';
import { showLoader, hideLoader } from '@/utils/helpers';
const collectionResource = new Resource('collections');

export default {
  name: 'createCollectionModal',
  components: {
    TheModal,
  },
  props: {
    collection: {
      type: Object,
      default: null,
      required: true,
    },
  },
  methods: {
    showLoader,
    hideLoader,
    deleteCollection() {

      this.loader = this.showLoader(this.loader);

      collectionResource.destroy(this.collection.id).then(() => {

       this.$notify({
        duration: 3500,
        title: 'Liste gelöscht!',
        text: 'Die Liste wurde erfolgreich gelöscht!'
      });

     })
      .finally(() => {
        this.loader = this.hideLoader(this.loader);
        this.$router.push({name:'Profil'});
        this.closeModal();
      });
    },
    closeModal(){
      this.$emit("closeModal");
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

</style>
