<template>
  <div>
    <vue-cookie-accept-decline
    :ref="'cookiemsg'"
    :elementId="'cookiemsg'"
    :position="'bottom-right'"
    :type="'floating'"
    :disableDecline="true"
    :transitionName="'slideFromBottom'"
    :showPostponeButton="false"
    @clicked-accept="cookieClickedAccept">

    <!-- Optional -->
    <div slot="postponeContent">
      &times;
    </div>

    <!-- Optional -->
    <div slot="message">
      <div class="gdprcookie" style="display: block;">
        <div class="gdprcookie-intro">
          <h1 class="mb20 mt10">Cookies und Datenschutz</h1>
          <p>In dem Kiel.KulturKompass der Landeshauptstadt Kiel verwenden wir Cookies in erster Linie dafür, technische Einstellungen, wie die Parameter Ihrer Suche zu speichern und um Ihnen den Login in Ihren Account zu ermöglichen. In der <router-link to="/datenschutz">Datenschutzerklärung</router-link> erhalten Sie eine Übersicht, wofür wir Cookies verwenden und welche Daten erhoben werden.</p>
        </div>
        <div class="gdprcookie-types">
          <h2 class="mb20 mt20">Wählen Sie zu akzeptierende Cookies aus.</h2>
          <ul class="list-inline">
            <li class="list-inline-item">
              <input type="checkbox" id="gdpr-cookietype-0" name="gdpr[]" value="essential" checked="checked" disabled="disabled">
              <label for="gdpr-cookietype-0" title="Diese Cookies sind notwendig damit diese Internetseite richtig funktioniert.">Notwendig</label>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Optional -->
    <div slot="declineContent">
     OPT OUT
   </div>

   <!-- Optional -->
   <div slot="acceptContent">
     <button ref="focusMe" type="button" class="btn btn-primary btn-focus-design">Cookies akzeptieren</button>
   </div>
 </vue-cookie-accept-decline>
</div>
</template>

<script>
  /*Vue Cookie banner*/
  import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
  import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

  export default {
    name: "cookieBanner",
    components: {
      VueCookieAcceptDecline
    },
    data() {
      return {
      };
    },
    methods:{
      cookieClickedAccept(){
        if(this.$refs.gdprAnalytics.checked){
          /*Enable Matomo*/
          /*this.$matomo.rememberConsentGiven();*/
        }
      }
    },
    mounted() {
      setTimeout(() => {
        //this.$refs.focusMe.focus();
      }, 2000);
    }
  }

</script>
<style type="text/css">

  #cookiemsg .cookie__floating__buttons .cookie__floating__buttons__button,#cookiemsg .cookie__floating__buttons .cookie__floating__buttons__button:hover, #cookiemsg .cookie__floating__buttons .cookie__floating__buttons__button:active{
    background-color: transparent;
    border: none!important;
    margin-bottom: 20px!important;
  }

  #cookiemsg {
    font-family: 'elliot-regular', sans-serif;
    color: #000;
    font-weight: 700;
    line-height: 1.8;
    font-size: 18px;
  }

  #cookiemsg.cookie__floating .cookie__floating__content{
    max-height: 100%!important;
    font-size: 16px!important;
    text-align: center;
  }

  #cookiemsg.cookie__floating {
    max-width: 350px!important;

    border-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-left-radius: 50px;

    box-shadow: -5px 5px 15px 0px rgba(0, 24, 62, 0.2);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  }

  #cookiemsg .list-inline-item label{
    margin-left: 10px;
    font-size: 15px;
  }

  #cookiemsg a{
    color: #E30059;
  }

  #cookiemsg a:hover{
    color: #00183E;
  }

  #cookiemsg h1{
    font-size: 25px;
  }

  #cookiemsg h2{
    font-size: 20px;
  }

  #cookiemsg .btn{
    font-size: 14px;
    padding: 10px 25px;
  }

</style>
