<template>
  <div class="login footer-margin">
    <Breadcrumb />

    <div class="container-fluid blank">
      <div id="login-row" class="row justify-content-center align-items-center blank-section">
        <div id="login-column">
          <div id="login-box" class="col-12">
            <form>
              <h3 class="text-center text-info">Anmelden</h3>

              <div class="form-group">
                <div class="inner-addon left-addon">
                  <i class="glyphicon material-icons icon" aria-hidden="true">email</i>
                  <input type="text" class="form-control" @blur="validateEmailInput" v-model="credentials.email" placeholder="E-Mail"/>
                </div>
                <span class="validation-error" v-show="showEmailError">Geben Sie Ihre E-Mail Adresse an.</span>
              </div>

              <div class="form-group" style="margin-bottom: 0px;">
                <div class="inner-addon left-addon">
                  <i class="glyphicon material-icons icon" aria-hidden="true">lock</i>
                  <input type="password" class="form-control"  @blur="validatePasswordInput" v-model="credentials.password" placeholder="Passwort"/>
                </div>
                <span class="validation-error" v-show="showPasswordError">Geben Sie Ihr Passwort ein. Das Passwort ist mindestens 8 Zeichen lang und enthält mindestens eine Zahl.</span>
              </div>

              <div class="form-group text-right mt20 mb20">
                <router-link :to="{ name: 'applyForNewPassword'}">
                  Passwort vergessen?
                </router-link>
              </div>

              <div class="form-group">
                <button type="submit" @click="dispatchLogin" style="width: 100%;" class="btn btn-fill btn-focus-design">Anmelden</button>
              </div>

              <div class="login-row" >
                <div class="text-center">Noch nicht dabei?</div>
                <router-link class="btn mt20 btn-focus-design" title="Neues Benutzerkonto anlegen" style="width: 100%;" :to="{ name: 'register'}">
                  Registrieren
                </router-link>
              </div>

              <div class="anbieter-notice login-row">
                Sie sind ein*e Anbieter*in für Kultur in Kiel? 
                <a href="https://bildungsangebote-kiel.de" title="Neues Konto für Anbieter*innen anlegen">
                  Jetzt kostenlos registrieren & inserieren!
                </a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <the-modal v-if="afterloginModal" @closeModal="afterloginModal=false">
      <template v-slot:header>
        <h5>Bitte überprüfe deine Mails</h5>
      </template>
      <template v-slot:body>
       <span>Wir haben dir eine Mail mit einem Bestätigungslink geschickt. Bitte aktiviere darüber deinen Account. Danach kannst du dich hier anmelden und Kiels Kultur entdecken!</span>
     </template>
     <template v-slot:footer>
      <div class="btn btn-fill" @click="afterloginModal=false">
        Alles klar
      </div>
    </template>
  </the-modal>

</div>
</template>

<script>
  import Breadcrumb from '@/components/controls/Breadcrumb.vue';
  import TheModal from '@/components/modal/show.vue';
  import {verify, csrf } from '@/api/auth';
  import { validEmail, validatePassword } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: 'login',
    components: {
      TheModal,
      Breadcrumb
    },
    data() {
      return {
       credentials: {
        email: "",
        password: "",
      },
      showEmailError: false,
      showPasswordError: false,
      afterloginModal: false, 
      isFirstRegistrationEver: false,
      loader: false,
    };
  },
  beforeCreate() {
    /*Wenn es um eine verifizierung geht*/
    if(this.$route.params.token){
      verify(this.$route.params.token).then( response => {
        this.$notify({
          duration: 2500,
          title: 'E-Mail Adresse verifiziert!',
          text: response
        });
      }).catch(() => {
        this.$notify({
          duration: 2500,
          title: 'Verifizierung fehlgeschlagen!',
          text: 'Die Verifizierung Ihrer E-Mail Adresse hat nicht funktioniert. Versuchen Sie es später nocheinmal oder kontaktieren Sie uns, wenn Sie sich nicht einloggen können.'
        });
      });
    }
  },
  mounted(){
    if(this.$route.query.afterRegistration){
      this.afterloginModal = true;
    }
  },
  computed:{
    token(){
      return this.$store.getters.getToken;
    },
  },
  methods: {
    verify,
    showLoader,
    hideLoader,
    validEmail,
    validatePassword,
    validatePasswordInput(){
      if(!this.validatePassword(this.credentials.password)){
        this.showPasswordError=true;
        return false;
      }else{
        this.showPasswordError=false;
        return true;
      }
    },
    validateEmailInput(){
      if(!validEmail(this.credentials.email)){
        this.showEmailError=true;
        return false;
      }
      else{
        this.showEmailError=false;
        return true;
      }
    },
    dispatchLogin(e){
      e.preventDefault();
      var emailValid = this.validateEmailInput();
      var passwordValid = this.validatePasswordInput();
      if(!emailValid || !passwordValid){
        return;
      }

      this.loader = this.showLoader(this.loader);

      csrf().then(() => {
        this.$store.dispatch('login', this.credentials).then(() => {
          this.$notify({
            duration: 2500,
            title: 'Erfolgreich angemeldet!',
            text: 'Sie sind im KulturKompass Kiel angemeldet.'
          });

          /*load the Collections for the user*/
          this.$store.dispatch('loadUserCollections');
          this.$router.back();
          
        }).catch(error => {
          this.$notify({
            duration: 4500,
            type: 'error',
            title: 'Fehler bei der Anmeldung!',
            text: error.data
          });
        }).finally(()=>{
          this.loader = this.hideLoader(this.loader);
        });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .login {
    position: relative;
    background: #fff;
    padding-top: 150px;
    padding-bottom: 140px;

    .anbieter-notice{
      margin-top:30px; 
      text-align:center;

      a{
        color:#E30059;

        &:hover{
          color:#0d6efd;
        }
      }
    }

    .blank{
      display: flex;
      align-items: center;
      height: 60vh;
    }

    .blank-section {
      width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .container-fluid {
      max-width: 1000px;

      #login-column{

        @media (max-width: 575px) {
          padding: 0px 50px;
        }

        @media (max-width: 400px) {
          padding: 0px 30px;
        }

      }


      h3.text-info {
        color: #00183E !important;
        margin-bottom: 30px;
      }

      .form-check{
        margin-bottom: 20px;      
      }

      .form-group {
        margin-bottom: 30px;

        @media (max-width: 575px) {
          margin-bottom: 20px;
        }

        .left-addon .glyphicon {
          left: 0px;
        }

        .inner-addon {
          position: relative;
        }

        .inner-addon .glyphicon {
          position: absolute;
          padding: 7px;
          pointer-events: none;
          color: #E30059;
        }

        .left-addon input {
          padding-left: 35px;
        }


      }
    }


  }
</style>
