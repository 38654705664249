<template>

  <the-modal @closeModal="closeModal" :isBig=true :showHeader=true>

    <template v-slot:header>
      <h5>Liste umbenennen</h5>
    </template>

    <template v-slot:body>

      <div>
        <div id="create-list-row" class="row justify-content-center align-items-center">

          <div class="form-group">
            <div class="inner-addon">
              <input type="text" class="form-control" @blur="validateListTitle" v-model="form.name" placeholder="Name der Liste"/>
            </div>
            <span class="validation-error" v-show="showListNameError">Geben Sie einen Namen für die Liste ein.</span>
          </div>

          <div class="form-group mt20">
            <button @click="dispatchUpdateCollection" style="width: 100%;" class="btn btn-fill">Liste umbenennen</button>
          </div>

        </div>
      </div>

    </template>

  </the-modal>

</template>
<script>
import TheModal from '@/components/modal/show.vue';
import Resource from '@/api/resource';
import { showLoader, hideLoader } from '@/utils/helpers';
const collectionResource = new Resource('collections');
import checkPermission from '@/utils/permission';

export default {
  name: 'editCollectionModal',
  components: {
    TheModal,
  },
  props: {
    collection: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      loader: null,
      showListNameError: false,
      options: [{
        value: 'privat',
        label: 'Private Merkliste'
      }], 
      form: {
        name: "",
        description: '',
        type: 'privat',
      },
    }
  },
  mounted(){
    this.form.name = this.collection.name;
    this.form.description = this.collection.description;
    this.form.type = this.collection.type;
  },
  computed: {
    user(){
      return this.$store.state.user;
    },
    institution(){
      if(this.user.institution!=null){
        return this.user.institution;
      }
      return null;
    },
  },
  methods: {
    showLoader,
    hideLoader,
    checkPermission,
    validateListTitle(){
      if(!this.form.name!=""){
        this.showListNameError=true;
        return false;
      }
      else{
        this.showListNameError=false;
        return true;
      }
    },
    dispatchUpdateCollection() {

      if(!this.validateListTitle()){
        return;
      }

      this.loader = this.showLoader(this.loader);

      collectionResource.update(this.collection.id,this.form).then(() => {
        this.$notify({
          duration: 2500,
          title: 'Liste umbenannt!',
          text: 'Die Liste wurde erfolgreich umbenannt.'
        });

      })
      .finally(() => {
        this.loader = this.hideLoader(this.loader);
        this.closeModal();
      });
    },
    closeModal(){
      this.$emit("closeModal");
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

</style>
