<template>
    <div>
        <div class="search">
            <form class="search-form" @submit.prevent>
                <div  class="form-element search-element">
                    <input type="text" ref="inputElement" class="search-icon" placeholder="Orte, Veranstaltungen und Meer ..." aria-label="Suchwort eingeben" v-model="query.selectedKeyword" v-on:keyup.enter="onEnter" @keypress.enter.prevent>
                </div>
                <button class="btn btn-link search-btn" @click="onEnter">
                    <img src="/assets/icons/right-arrow.png" alt="Pfeil nach rechts">
                </button>
            </form>
        </div>
    </div>
</template>

<script>
    import { DatePicker } from 'v-calendar';
    import FilterButton from '@/components/controls/FilterButton.vue';
    import { getWeekday, getMonth } from '@/utils/helpers';

    export default {
        name: 'SearchBar',
        components: {
            DatePicker,
            FilterButton,
        },
        props: {
            keyword: {
                type: String,
            default: ''
            }
        },
        data() {
            return {
                query:{
                    selectedKeyword: null,
                },
            }
        },
        methods: {
          onEnter(){
            if(this.query.selectedKeyword){
                this.$refs.inputElement.blur();
                this.$emit('searchWordChanged',this.query.selectedKeyword);
            }
        },
    }
}
</script>
<style lang="scss" scoped>

    @import '@/scss/_variables.scss';

    .search {
        margin-top: 40px;

        @media(max-width: 991px){
            margin-top: 20px;
        }

        &-form {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media (max-width: 990px){
                flex-wrap: wrap;
            }

            .form-title {
                margin-right: 40px;

                @media (max-width: 1200px){
                    margin-right: 10px;
                }

                @media (max-width: 990px){
                    width: 45%!important;
                    margin-bottom: 20px;
                }

                h1{
                    font-size: 20px;
                }

            }

            .form-element {
                width: 100%;
            }
        }

        &-icon {
            padding: 0.375rem 2rem;
        }
    }

    .search-btn {
        position: absolute;
        right: 17px;
        border-radius: 5px;
        padding: 8px !important;

        &:hover {
          box-shadow: 0 0px 10px rgba(0, 0, 0, 0.4);
      }

      img {
        height: 18px;
        width: 18px;
    }
}
</style>