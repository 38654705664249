<template>
    <div class="profile footer-margin">
        <Breadcrumb />

        <div class="container">

            <div class="institution-header">
                <div class="profile-image">
                    <splide :options="sliderOptions" ref="teaserCarousel" class="full-width" v-if="sliderImages!=null && sliderImages.length>1">
                      <splide-slide class="full-width" v-for="(slide,index) in sliderImages" :key="index" :aria-label="slide.description">
                        <div tabindex="-1" class="full-width profile-image-placeholder test2" :class="slide.class" :style="getBackgroundImage(slide.image)"></div> 
                        <span class="copyright" v-if="slide.copyright!=null">&copy; {{slide.copyright}}</span>
                    </splide-slide>
                </splide>
                <div v-else-if="sliderImages!=null && sliderImages.length==1" :aria-label="sliderImages[0].description">
                    <div tabindex="-1" class="full-width profile-image-placeholder" :class="sliderImages[0].class" :style="getBackgroundImage(sliderImages[0].image)"></div>
                    <span class="copyright" v-if="sliderImages[0].copyright!=null">&copy; {{sliderImages[0].copyright}}</span>
                </div>
                <button title="Merken" class="btn btn-link btn-favorite special" :class="isInCollectionClass" @click="showAddToCollectionDialog=true">
                    <i class="material-icons" aria-hidden="true">anchor</i>
                </button>
            </div>
            <div class="profile-heading d-flex justify-content-between">
                <div class="profile-heading__title">
                    <span>{{categories}}</span>
                    <h4>{{title}}</h4>
                </div>

                <share-button :is-device-mobile="isItMobile"/>

            </div>
        </div>

        <ContactInformation :url="url" :mail="mail" :phone="phone"/>

        <div class="row time-and-place">
         <div class="col-lg-6 col-md-6 col-12 time-and-place-col">
            <Appointments :appointments="appointments"/>
        </div>

        <div class="col-lg-6 col-md-6 col-12 time-and-place-col" style="text-align: center;" v-if="addressObj != null">
           <div :class="['dropdown', isActiveLocation ? 'is-active': '']">
            <button class="dropdown-head no-btn" @click="toggleMap">
                <div class="inline-icon-text">
                    <i class="material-icons icon" aria-hidden="true">location_on</i>
                    <h5>{{adresse}}</h5>
                </div>
            </button>
        </div>
    </div>
</div>

<div :class="['dropdown', isActiveLocation ? 'is-active': '']" v-if="addressObj">
    <div class="dropdown-list map-dropdown" style="height: 500px;">
        <the-map :address-obj="addressObj"></the-map>
    </div>
</div>

<div class="profile-text mt30 mb30">
    <CardSimple :description="description" :tags="tags"/>
</div>

<div class="profile-events row mb30">
    <institution-events v-if="institution!=null" style="width:100%;" :institution="institution"/>
</div>

<div class="profile-collections row mb30" v-if="collectionDataSet.data!=null && collectionDataSet.total>0">
    <institution-collections style="width:100%;" :data-set="collectionDataSet" :institution="institution" @change-page="pageChanged"/>
</div>

<CardConnection :address="adresse" />

</div>

<AddContentToCollection :content="aContent" v-if="showAddToCollectionDialog" @closeModal="showAddToCollectionDialog=false"></AddContentToCollection>

</div>
</template>

<script>
    import Resource from '@/api/resource';
    const institutionResource = new Resource('institutions');

    import { isMobile } from 'mobile-device-detect';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { getInstitutionCollections } from '@/api/institution'
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';
    import { getFieldValues, showLoader, hideLoader, json_decode } from '@/utils/helpers';

    export default {
        name: 'Anbieterprofile',
        components: {
            Splide,
            SplideSlide,
            TheMap: () =>import('@/components/map/single.vue'),
            InstitutionEvents: () =>import('./components/events.vue'),
            ContactInformation: () =>import('@/components/details/contactInformation.vue'),
            CardSimple: () =>import('@/components/cards/CardSimple.vue'),
            Breadcrumb: () =>import('@/components/controls/Breadcrumb.vue'),
            ShareButton: () =>import('@/components/controls/ShareButton.vue'),
            Appointments: () =>import('@/components/details/appointments.vue'),
            InstitutionCollections: () =>import('./components/collections.vue'),
            CardConnection: () =>import('@/components/cards/CardConnection.vue'),
            AddContentToCollection: () =>import('@/components/modal/addContentToCollection/dialog.vue')
        },
        data() {
            return {
                loader: null,
                institution: null,
                sliderImages: null,
                sliderOptions: {
                    type: 'loop',
                },
                showAddToCollectionDialog: false,
                isItMobile: isMobile ? true : false,
                collectionDataSet: {
                  total: 0,
                  data: null,
                  meta:null,
                  query: {
                    page:1,
                    limit: 6,
                    selectedAppId: this.$appId,
                    keyword: '',
                    selectedContentTypeId: null,
                    view_status: 1,
                    sort: {
                      prop: '',
                      order: 'asc'
                  },
              },
          },
          isActiveLocation: false,
          isActiveTime: false,
      }
  },
  computed: {
      id() {
        return this.$route.params.id;
    },
    isLoggedIn(){
        if(this.$store.getters.getToken){
            return true;
        }
        return false;
    },
    collections(){
      return this.$store.getters.getCollections;
  },
  isInCollectionClass(){
    if(this.isLoggedIn && this.isContentInCollection()){
        return 'active';
    }
    return '';
},
aContent(){
    if(this.institution != null && Object.prototype.hasOwnProperty.call(this.institution,'content') && this.institution.content!=null){
        return this.institution.content;
    }
    return null;
},
title(){
    if(this.aContent!=null){
        return this.aContent.title;
    }
    return "";
},
categories(){
    var cats = getFieldValues(this.aContent,'kultur_-_kategorien');
    if(Array.isArray(cats)){
        return cats.join(', ');
    }
    else if(cats!=null){
        return cats;
    }
    return "";
},
contactInfo(){
    return getFieldValues(this.aContent,'kontaktinformationen');
},
addressObj(){
    return getFieldValues(this.aContent,'adresse');
},
adresse(){
    if(this.addressObj!=null){
        return this.addressObj.street+', '+this.addressObj.zipcode+' '+this.addressObj.city;
    }
    return '';
},
appointments(){
    return getFieldValues(this.aContent,'appointments');
},
tags(){
    var tags = getFieldValues(this.aContent,'kultur_-_tags');
    if(tags != null){
        if(Array.isArray(tags)){
            return tags;
        }
        else{
            return [tags];
        }
    }
    return null;
},
description(){
    var desc = getFieldValues(this.aContent,'description');
    return desc!=null?desc:'';
},
teaserImages(){
    var pictures = getFieldValues(this.aContent,'teaser-bild');
    if(pictures != null){
        if(Array.isArray(pictures)){
            return pictures;
        }
        else{
            return [pictures];
        }
    }
    return null;
},
logo(){
    var logo = getFieldValues(this.aContent,'logo');
    return json_decode(logo);
},
url(){
    return getFieldValues(this.aContent,'url');
},
mail(){
    return getFieldValues(this.contactInfo,'e-mail_adresse');
},
phone(){
    return getFieldValues(this.contactInfo,'telefonnummer');
},
currentPath(){
    return window.location.href;
},
shareViaEmail(){
    return "mailto:?body="+this.currentPath;
},
},
created() {
  this.getInstitution();
  this.isActiveLocation=true;
},
methods: {
    showLoader, 
    hideLoader,
    json_decode,
    getSlides(){
        var images = [];
        if(this.logo != null){
            if(typeof this.logo === "object"){
                images.push({image:this.$backendUrl+this.logo.path,class:'logo', copyright:this.logo.copyright,description:this.logo.description});
            } 
            else {
                var logo = this.logo.charAt(0)!="/"?"/"+this.logo:this.logo;
                images.push({image:this.$backendUrl+logo,class:'logo', copyright:null,description:null});
            }
        }

        if(this.teaserImages != null){
            for(var i = 0; i < this.teaserImages.length; i++){
                var image = json_decode(this.teaserImages[i]);
                if(typeof image === "object"){
                    images.push({image:this.$backendUrl+image.path,class:'teaser-img', copyright:image.copyright,description:image.description});
                }
                else {
                  images.push({image:this.$backendUrl+image,class:'teaser-img', copyright:null,description:null});  
              }
          }
      }

      /*add the logo as the first image to the array*/
      if(images.length == 0){
        images.push({image:'/assets/image.jpg',class:'teaser-img', copyright:null,description:null});
    }
    return images;
},
getBackgroundImage(image){
    return 'background-image: url("' + image + '")';
},
onShareError(err) {
    alert(err);
    console.log(err);
},
onShareSuccess(err) {
    console.log(err);
},
getInstitution() {
    this.loader = this.showLoader(this.loader);
    institutionResource.get(this.id)
    .then(response => {
      this.institution = response.data;
      this.sliderImages = this.getSlides();
      this.getInstitutionCollections();
  })
    .finally(() => {
      this.loader = this.hideLoader(this.loader);
  });
},
async getInstitutionCollections(){
    const { data } = await getInstitutionCollections(this.institution.id);
    this.collectionDataSet.data = data;
    this.collectionDataSet.meta = null;
    this.collectionDataSet.total = data.length;
},
isContentInCollection(){
    if(this.collections!=null){
        for (var prop in this.collections) {
            var collectionGroup = this.collections[prop];
            for(var i=0; i<collectionGroup.length; i++){
                var currentCollection = collectionGroup[i];
                if(currentCollection!=null && currentCollection.hasOwnProperty('contents') && currentCollection.contents.length>0){
                    for(var j=0; j<currentCollection.contents.length; j++){
                        if(currentCollection.contents[j]!=null && this.aContent != null && currentCollection.contents[j].id == this.aContent.id){
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
},
toggleUhrzeit(){
    this.isActiveTime = !this.isActiveTime;
    /*hide map if activeTime is shown*/
    if(this.isActiveTime){
        this.isActiveLocation = false;
    }
},
toggleMap(){
    this.isActiveLocation = !this.isActiveLocation;
    /*hide map if activeTime is shown*/
    if(this.isActiveLocation){
        this.isActiveTime = false;
    }
}
},
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .profile {
        position: relative;
        background: #fff;
        padding-top: 110px;
        padding-bottom: 40px;

        .container {
            max-width: 705px;
        }

        .center-md{
            @media (max-width: 991px) {
                text-align:center!important;
            }
        }

        .btn.btn-favorite.special .material-icons{
            color: #E30059;
            border: 2px solid #E30059;
        }

        .btn.btn-favorite.special .material-icons:hover{
            color: #fff;
            background-color: #E30059;
        }

        .btn.btn-round.special{
            color: #00183E;
            border: 2px solid #00183E;
        }

        .btn.btn-round.special:hover,.btn.btn-round.special:active,.btn.btn-round.special:focus{
            color: #fff;
            background-color: #00183E;
        }

        .display-appointments{
            margin-left: 40px;
        }

        .institution-header{
            @media (max-width: 767px) {
                padding: 0 20px;
            }
        }

        .time-and-place{
            margin-top: 20px;

            @media (max-width: 991px) {
                margin-top: 10px;
            }

            @media (max-width: 501px) {
                margin-top: 0px!important;
            }

            .time-and-place-col{

                @media (max-width: 767px) {
                    width: 100%;
                    text-align: center!important;
                    margin-top: 10px;
                }

                i{
                    vertical-align: bottom;
                    margin-right: 10px;
                }

                h5 {
                    font-size: 16px;
                }

            }

        }

        .map-dropdown{

            @media (max-width: 560px) {
                height: 400px!important;
            }

            @media (max-width: 480px) {
                height: 350px!important;
            } 
        }

        &-image {
            position: relative;
            z-index: 1;

            &-placeholder {
                height: 371px;
                background-repeat: no-repeat;  
                background-position: center center; 
                background-size: cover;
                &.logo{
                    background-size: contain!important;
                }

                @media (max-width: 560px) {
                    height: 260px;
                }

                @media (max-width: 480px) {
                    height: 220px;
                }
            }

            @media (max-width: 767px) {
                margin-left: -15px;
                margin-right: -15px;
            }
        }



        &-heading {
            position: relative;
            z-index: 2;
            top: 0;
            padding: 10px 20px;
            box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
            border-radius: 5px;
            border-bottom-left-radius: 30px;

            @media (max-width: 767px) {
                margin-left: -15px;
                margin-right: -15px;
            }

            &__title{
                width: calc(100vw - 70px);
                margin-bottom: 10px;


                h4{
                    margin-top: 5px;
                }
            }
        }

        @media (max-width: 767px) {
            padding-top: 70px;
        }

        @media (max-width: 350px) {
            padding-top: 60px;
        }
    }
</style>
