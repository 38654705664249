<template>
  <div class="login footer-margin">
    <Breadcrumb />

    <div class="container-fluid blank">
      <div id="login-row" class="row justify-content-center align-items-center blank-section">
        <div id="login-column">
          <div id="login-box" class="col-12">
            <h3 class="text-center text-info">Meine Daten</h3>

            <div class="form-group">
              <div class="inner-addon left-addon">
                <i class="glyphicon material-icons icon" aria-hidden="true">person</i>
                <input type="text" class="form-control"  @blur="validateNameInput" v-model="credentials.name" placeholder="Vor- und Nachname"/>
              </div>
              <span class="validation-error" v-show="showNameError">Geben Sie Ihren Vor- und Nachnamen ein.</span>
            </div>

            <div class="form-group">
              <div class="inner-addon left-addon">
                <i class="glyphicon material-icons icon" aria-hidden="true">email</i>
                <input type="text" readonly class="form-control"  v-model="credentials.email" placeholder="E-Mail"/>
              </div>
            </div>

            <div class="form-group">
              <button @click="updateUser" style="width: 100%;" class="btn btn-fill">Änderungen übernehmen</button>
            </div>

            <div class="form-group">
              <button style="width: 100%;" @click="showDeleteUserModal=true" class="btn">Account löschen</button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <the-modal v-if="showDeleteUserModal" @closeModal="showDeleteUserModal=false">
      <template v-slot:header>
        <h5>Account löschen</h5>
      </template>
      <template v-slot:body>
       <span>Möchten Sie Ihren Account wirklich endgültig löschen?</span>
     </template>
     <template v-slot:footer>
      <div class="btn btn-fill" @click="showDeleteUserModal=false, removeUser()">
        Account löschen
      </div>
    </template>
  </the-modal>

</div>
</template>

<script>
  import Breadcrumb from '@/components/controls/Breadcrumb.vue';
  import { showLoader, hideLoader } from '@/utils/helpers';
  import TheModal from '@/components/modal/show.vue';
  import Resource from '@/api/resource';
  const userResource = new Resource('users');

  export default {
    name: 'my-data',
    components: {
      TheModal,
      Breadcrumb
    },
    data() {
      return {
       credentials: {
        id: '',
        name: '',
        email: '',
      },
      showDeleteUserModal: false,
      showNameError: false,
      loader: false,
    };
  },
  computed:{
    user(){
      return this.$store.state.user;
    },
  },
  mounted(){
    this.credentials.id = this.user.id;
    this.credentials.name = this.user.name;
    this.credentials.email = this.user.email;
  },
  methods: { 
    showLoader,
    hideLoader,
    validateNameInput(){
      if(this.credentials.name == "" || this.credentials.name.length<4){
        this.showNameError = true;
        return false;
      }
      this.showNameError = false;
      return true;
    },
    updateUser(e){
      e.preventDefault();
      var nameValid = this.validateNameInput();
      if (nameValid) {
        this.loader = this.showLoader(this.loader);
        userResource.update(this.user.id,this.credentials).then(() => {

          this.$notify({
            duration: 3500,
            title: 'Daten aktualisiert!',
            text: 'Ihre Daten wurden erfolgreich aktuallisiert!'
          });

          /*Update the User-Data with the Data from the Server*/
          this.$store.dispatch('loadUser');
          this.$router.push({name:'Home'});

        })
        .catch(() => {

         this.$notify({
          type: 'error',
          duration: 2500,
          title: 'Aktualisierung fehlgeschlagen!',
          text: 'Ihre Daten konnten nicht gespeichert werden. Bitte probieren Sie es zu einem späteren Zeitpunk noch einmal!'
        });

       })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
        });
      }
    },
    removeUser(e) {
      e.preventDefault();
      this.loader = this.showLoader(this.loader);
      /*und dann das ganze an die APi übergeben*/
      userResource.destroy(this.user.id).then(() => {

        this.$notify({
          duration: 3500,
          title: 'Account gelöscht!',
          text: 'Ihr Benutzeraccount wurde erfolgreich gelöscht!'
        });

        this.$store.dispatch('resetUserAndToken');
        this.$router.push({name:'Home'});

      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loader = this.hideLoader(this.loader);
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .login {
    position: relative;
    background: #fff;
    padding-top: 110px;
    padding-bottom: 40px;

    .blank{
      display: flex;
      align-items: center;
      height: 60vh;
    }

    .blank-section {
      width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .container-fluid {
      max-width: 1000px;

      #login-column{

        @media (max-width: 575px) {
          padding: 0px 50px;
        }

        @media (max-width: 400px) {
          padding: 0px 30px;
        }

      }


      h3.text-info {
        color: #00183E !important;
        margin-bottom: 30px;
      }

      .form-check{
        margin-bottom: 20px;      
      }

      .form-group {
        margin-bottom: 30px;

        @media (max-width: 575px) {
          margin-bottom: 20px;
        }

        .left-addon .glyphicon {
          left: 0px;
        }

        .inner-addon {
          position: relative;
        }

        .inner-addon .glyphicon {
          position: absolute;
          padding: 7px;
          pointer-events: none;
          color: #E30059;
        }

        .left-addon input {
          padding-left: 35px;
        }


      }
    }


  }
</style>
