<template>
    <div class="footer-fix">
        <div class="bg-image">
            <Banner />
            <div class="section">
                <div class="row card-container">
                    <div v-for="category in categories" class="col-6 col-lg-3 cat">
                        <CardCategory :isSmall=true class="category-card" :key="category.id" :content="category"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Banner from './components/Banner.vue';
    import CardInstitution from '@/components/cards/CardInstitution.vue';
    import CardCategory from './components/CardCategory.vue';
    import CardText from './components/CardText.vue';
    import TheMap from '@/components/map/area.vue';

    import Resource from '@/api/resource';
    const institutionResource = new Resource('institutions');
    import { showLoader, hideLoader } from '@/utils/helpers';

    export default {
        name: 'Home',
        components: {
            TheMap,
            Banner,
            CardInstitution,
            CardCategory,
            CardText
        },
        data() {
            return {
                loader: null,
                //categories: [{title: 'KulTouren'}, {title: 'Kalender'}, {title: 'Karte'}, {title: 'Login'}],
                categories: [ {title: 'Kalender'}, {title: 'Karte'}, {title: 'Login'}],
                institutionDataSet: {
                  total: 0,
                  data: null,
                  meta:null,
                  query: {
                    page:1,
                    limit: 5,
                    app_id: this.$appId,
                    keyword: '',
                    type: 'view',
                    contentTypeId: null,
                    selectRandomly: true,
                    view_status: 1,
                    sort: {
                      prop: '',
                      order: 'asc'
                  },
              },
          },
      }
  },
  computed: {
  },
  created() {
    this.getInstitutionList();
},
methods:{
    showLoader, 
    hideLoader,
    async getInstitutionList() {
        this.loader = this.showLoader(this.loader);
        const { limit, page } = this.institutionDataSet.query;
        this.contentLoading = true;
        const { data, meta } = await institutionResource.list(this.institutionDataSet.query);
        this.institutionDataSet.data = data;
        this.institutionDataSet.data.forEach((element, index) => {
            element['index'] = (page - 1) * limit + index + 1;
        });
        this.institutionDataSet.meta = meta;
        this.institutionDataSet.total = meta.total;
        this.loader = this.hideLoader(this.loader);
    },
}
}
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .institution-card{
        max-width: 31%;
    }

    .recommendations {
        @media (max-width: 1090px) and (min-width: 992px) {
            width: 100%;
        }
    }

    .special-of-the-day{
        padding: 0px 15px;
    }

    .special-of-the-day-row{
        @media (max-width: 992px) {
            padding-top: 20px;
            width: 100%;
        }
    }


    .container {

        @media (max-width: 992px) {
            max-width: 100%;
            padding: 0px 40px;
        }


        @media (max-width: 500px) {
            padding: 0px 20px;
        }

    }

    .in-my-area-map{
        @media (max-width: 560px) {
            min-height: 400px!important;
        }

        @media (max-width: 480px) {
            min-height: 350px!important;
        } 
    }


    .scroll-container{

        @media (max-width: 992px) {
            flex-wrap: wrap;
        }

        @media (max-width: 731px) {
            flex-wrap: nowrap;
        }
    }

    .category-row{
        @media (max-width: 731px) {
            padding-top: 10px;
        }
    }

    .footer-fix {
        background-color: #FFF;
    }

    .bg-image {
        background-image: url('/assets/logos/bg_img.png');
        background-position: 60vw -5vh;
        background-repeat: no-repeat;
        height: calc(100vh - 146px);
        background-size: 1000px 1000px;

        @media(max-width: 1500px){
            background-position: 50vw -5vh;
        }

        @media(max-width: 1200px){
            background-position: 40vw -10vh;
        }

        @media(max-width:991px){
            background-size: 500px, 500px;
            background-position: 50vw 0vh;
            height: 100vh;
            margin-bottom: 146px;
        }

        @media(max-width: 550px){
            background-position: 30vw 0vh;
        }

        @media(max-width: 400px){
            background-position: 20vw 0vh;
        }

        @media(max-width:991px) and (min-height: 850px){
          height: calc(100vh - 146px);
          margin-bottom: 0;  
      }

      @media(max-height:750px){
        height: auto !important;
    }

    @media(min-width: 992px) and (max-height: 900px){
        height: 100vh;
        margin-bottom: 146px;
    }
}

.section {
    background-color: transparent;
}

.card-container {
    margin-left: 20px;
    margin-right: 20px;

    @media(max-width:991px){
        margin-left: 10px;
        margin-right: 10px;
    }

    @media(max-width: 500px){
        margin-left: 5px;
        margin-right: 5px;
    }
}

.cat {

    padding-left: 0;
    padding-right: 0;

}

.category-card{
    margin-right: 5px;
    margin-left: 5px;
}


</style>