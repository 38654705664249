<template>
    <div class="page footer-margin">
        <Breadcrumb />

        <div class="page__breadcrumb">
            <div class="container-fluid">
                <div class="page-info">
                    <div class="page-info__item">
                        <h4>Dein Profil</h4>
                    </div>
                    <div class="page-info__item">

                     <router-link class="btn btn-profile icon-before mr20" :to="{ name: 'newPassword'}">
                        <i class="material-icons" aria-hidden="true">lock</i>
                        <span>Passwort ändern</span>
                    </router-link>

                    <router-link class="btn btn-profile icon-before" :to="{ name: 'myData'}">
                        <i class="material-icons" aria-hidden="true">person</i>
                        <span>Meine Daten</span>
                    </router-link>

                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="hide-on-mobile">

            <div class="row" v-if="hasAnyCollections">

                <div class="col-12 page-lists page-lists-desktop" v-for="(listType,index) in collections" :key="index">
                    <div v-if="hasCollections(listType) && index!='apps'">
                        <h3 class="title" :class="index=='own'?'page-lists-title':''">{{getCollectionTypeTitle(index)}}</h3>

                        <div class="row">
                         <div v-for="(list,aindex) in getCollections(index,listType)" :key="aindex" class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt20">
                            <CardCollection class="card-collections" :collection="list" :isSmall="true"/>
                        </div>

                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mt20" v-if="index=='own'">
                            <div class="card add-new-list-card" @click="showAddNewCollection=true" @keyup.enter="showAddNewCollection=true" tabindex="0">

                                <div class="card-body">

                                    <div class="img-placeholder">
                                        <span class="icon material-icons" aria-hidden="true">add_circle_outline</span>
                                    </div>

                                    <span class="title">Neue Liste erstellen</span>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="row mb30 mt30" v-else>
            <div class="error-msg">
                <img style="padding-top: 30px; padding-bottom: 0px;" src="/assets/intro/OnboardingAnker.png" alt="Intro-Img 2"/>
                <h4>Keine Merklisten vorhanden.</h4>
                <div class="mt10">Nutze den Anker-Button, um dir Inhalte für später zu merken. Erstelle persönliche Listen, um deine Favoriten nach Themen zu sortieren.</div>
            </div>
        </div>
    </div>

    <div class="only-on-mobile">
        <div class="profil-tabs">

            <div class="row" v-if="hasAnyCollections">

                <div class="col-12 page-lists" v-for="(listType,index) in collections" :key="index">
                    <div v-if="hasCollections(listType) && index!='apps'">
                        <h3 class="title mb20">{{getCollectionTypeTitle(index)}}</h3>

                        <div>
                            <div v-for="(list,aindex) in getCollections(index,listType)" :key="aindex">
                                <CardCollection class="card-collections" :collection="list" :isSmall="true"/>
                            </div>

                            <div class="card add-new-list-card" v-if="index=='own'" @click="showAddNewCollection=true">

                                <div class="card-body">

                                    <div class="img-placeholder">
                                        <span class="icon material-icons" aria-hidden="true">add_circle_outline</span>
                                    </div>

                                    <span class="title">Neue Liste erstellen</span>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <div class="row mb30 mt30" v-else>
                <div class="error-msg">
                    <img style="padding-top: 30px; padding-bottom: 0px;" src="/assets/intro/OnboardingAnker.png" alt="Intro-Img 2"/>
                    <h4>Keine Merklisten vorhanden.</h4>
                    <div class="mt10">Nutze den Anker-Button, um dir Inhalte für später zu merken. Erstelle persönliche Listen, um deine Favoriten nach Themen zu sortieren.</div>
                </div>
            </div>
        </div>
    </div>

</div>

<CreateCollectionModal v-if="showAddNewCollection" @closeModal="closeCollectionDialogAndReloadCollection"></CreateCollectionModal>

</div>
</template>

<script>
    import Breadcrumb from '@/components/controls/Breadcrumb.vue';
    import CardCollection from '@/components/cards/CardCollection.vue';
    import { showLoader, hideLoader } from '@/utils/helpers';
    import CreateCollectionModal from '@/components/modal/createCollection.vue';
    import { getCollectionsOfUser } from '@/api/collection';


    const SECTIONS = Object.freeze({
        FAVORITEN: 'FAVORITEN',
        LISTEN: 'LISTEN'
    });

    export default {
        name: 'Profil',
        components: {
            Breadcrumb,
            CardCollection,
            CreateCollectionModal
        },
        data() {
            return {           
                SECTIONS,
                active: SECTIONS.FAVORITEN,
                loader: null,
                showAddNewCollection: false,
            }
        },
        computed:{
            user(){
              return this.$store.state.user;
          },
          collections(){
            return this.$store.getters.getCollections;
        },
        hasAnyCollections(){
            if(this.collections!=null){
                for (var prop in this.collections) {
                    if (Object.prototype.hasOwnProperty.call(this.collections, prop)) {
                        if(this.hasCollections(this.collections[prop])){
                            return true;
                        }
                    }
                }

            }
            return false;
        }
    },
    mounted(){
        this.loadCollections();
    },
    methods: {
        showLoader,
        hideLoader,
        getCollectionsOfUser,
        closeCollectionDialogAndReloadCollection(){
            this.showAddNewCollection=false;
            this.loadCollections();
        },
        hasCollections(listType){
            if(listType.length>0){
                return true;
            }
            return false;
        },
        getCollectionTypeTitle(listType){
            var title = "";
            switch(listType){
                case "shared":
                title = "Mit mir geteilte Merklisten";
                break;
                case "own":
                title = "Eigene Merklisten";
                break;
                case "institution":
                title = "Merklisten meiner Institution";
                break;
                case "apps":
                title = "Merklisten dieser App";
                break;
            }
            return title
        },
        loadCollections(){
            this.loader = this.showLoader(this.loader);
            this.$store.dispatch('loadUserCollections').then(() => {
            }).catch(error => {
                this.$notify({
                  duration: 4500,
                  type: 'error',
                  title: 'Fehler bei der Anmeldung!',
                  text: error
              });
            }).finally(()=>{
                this.loader = this.hideLoader(this.loader);
            });
        },
        getCollections(listType,listArray){
            if(listType!="apps"){
                return listArray;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .error-msg{
        text-align:center; 
        width: 500px; 
        margin: 0 auto;
    }

    .page {
        position: relative;
        background: #fff;
        padding-top: 70px;
        padding-bottom: 50px;

        &__breadcrumb {
            margin-bottom: 20px;
            border-radius: 5px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            padding: 35px 0 25px 0;

            .page-info {
                display: flex;
                margin-left: 60px;
                align-items: center;
                justify-content: space-between;

                .btn.btn-profile {
                    width: 220px;

                    i.material-icons {
                        left: 20px;
                        @media (max-width: 786px) {
                            position: relative;
                            top: 5px!important;
                            left: 0;
                        }

                    }

                    span {
                        @media (max-width: 786px) {
                            display: none;
                        }
                    }

                    @media (max-width: 786px) {
                        width: 40px;
                        height: 40px;
                        padding: 0;
                        border-radius: 100px;
                    }
                }
            }

            @media (max-width: 786px) {
                box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
            }
        }

        .card-collections{
            margin-bottom: 20px!important;
        }

        .page-lists-title:first-of-type{
            @media (max-width: 991px) {
                margin-top: 0px;
            }    
        }

        .add-new-list-card{
            color: #fff;
            border: none;
            height: 178px;
            cursor: pointer;
            min-width: 264px;
            position: relative;
            border-radius: 0px;
            text-align: center;
            background-color: #E30059;
            border-bottom-left-radius: 5px;
            border-bottom-left-radius: 30px;

            box-shadow: -5px 5px 15px 0px rgba(0, 24, 62, 0.2);
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            .img-placeholder{
                height: 105px;
                text-align: center;
                padding-top: 20px;

                .icon {
                    font-size: 70px;
                }
            }

            .title{
                font-weight: bold;
                color: inherit;
                font-size: 18px;
                text-align: center;
            }

            &:hover{
                color: #E30059;
                background-color: #fff;
                border: 2px solid #E30059;
            }

            &:focus-visible {
               color: #E30059;
               background-color: #fff;
               border: 2px solid #E30059; 
               border-bottom-left-radius: 30px !important;
           }

       }

       &-favorites,
       &-lists {

        .title {
            color: $primary;
            margin: 20px 0 0;
        }

        .title__label {
            font-size: 16px;
            color: $primary;
            margin-top: 20px;
        }
    }

    .profil-tabs {
        position: relative;

        .tabs-control {
            display: flex;
            margin-left: -15px;
            margin-right: -15px;
            text-align: center;

            &__item {
                flex: 1;
                padding: 15px 0;
                cursor: pointer;

                &:first-child {
                    border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;
                    border-bottom: 1px solid $lightGray;

                    &::after {
                        content: '';
                        width: 1px;
                        background: $lightGray;
                        position: absolute;
                        height: 30px;
                        top: 10px;
                        left: 50%;
                        z-index: 999;
                        right: 0;
                    }
                }
                &:last-child {
                    border-bottom-left-radius: 10px;
                    border-top-left-radius: 10px;
                    border-bottom: 1px solid $lightGray;
                }

                &.is-active {
                    border-top: 1px solid $lightGray;
                    border-bottom: none
                }
            }
        }

        .tabs-content {
            padding: 20px 0;

            .tabs-content__item {
                display: none;
                flex-direction: column;
                vertical-align: middle;
                max-width: 500px;
                margin: 0 auto;

                &.is-active {
                    display: flex;
                }

                &--single {
                    margin-bottom: 20px;

                    &-head {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 20px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .btn-bottom {
        display: block;

        @media (max-width: 786px) {
            margin: 0 auto;
            background: #fff;
            color: $secondary;
        }

    }

    @media (max-width: 786px) {
        padding-top: 60px;
    }
}
</style>
