<template>
  <div class="apply-for-new-password footer-margin">
    <Breadcrumb />

    <div class="container-fluid blank">
      <div id="apply-for-new-password-row" class="row justify-content-center align-items-center blank-section">
        <div id="apply-for-new-password-column">
          <div id="apply-for-new-password-box" class="col-12">
            <h3 class="text-center text-info">Passwort zurücksetzen</h3>

            <div class="form-group text-center">
              <span class="text">
                Um Ihr Passwort zurückzusetzen geben Sie bitte Ihre E-Mail Adresse ein. Sie erhalten anschließend eine E-Mail mit einem Link mit dem Sie Ihr Passwort zurückzusetzen können.
              </span>
            </div>

            <div class="form-group">
              <div class="inner-addon left-addon">
                <i class="glyphicon material-icons icon" aria-hidden="true">email</i>
                <input type="text" class="form-control" @blur="validateEmailInput(), checkIfExists()" v-model="credentials.email" placeholder="E-Mail"/>
              </div>
              <span class="validation-error" v-show="showEmailExistsError">Es gibt keinen Account mit dieser E-Mail Adresse.</span>
              <span class="validation-error" v-show="showEmailError">Geben Sie Ihre E-Mail Adresse an.</span>
            </div>

            <div class="form-group">
              <button @click="handleApplyForPassword" style="width: 100%;" class="btn btn-fill btn-focus-design" title="">Passwort zurücksetzen</button>
            </div>

            <div class="apply-for-new-password-row">
              <div class="text-center">Noch nicht dabei?</div>
              <router-link class="btn mt20 btn-focus-design" title="Neues Benutzerkonto anlegen" style="width: 100%;" :to="{ name: 'register'}">
                Registrieren
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import Breadcrumb from '@/components/controls/Breadcrumb.vue';
  import { validEmail } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';
  import { applyResetPassword, csrf, emailExists } from '@/api/auth';

  export default {
    name: 'password-apply',
    components: {
      Breadcrumb
    },
    data() {
      return {
       credentials: {
        email: "",
        appId: this.$appId,
        appUrl: window.location.origin,
      },
      showEmailExistsError: false,
      showEmailError: false,
      loader: false,
    };
  },
  methods: {
    showLoader,
    hideLoader,
    validEmail,
    validateEmailInput(){
      if(!validEmail(this.credentials.email)){
        this.showEmailError=true;
        return false;
      }
      else{
        this.showEmailError=false;
        return true;
      }
    },
    checkIfExists(){
      var theMail = {email: this.credentials.email};
      emailExists(theMail).then(response => {
        if(response.status == "204"){
          /*no user*/
          this.showEmailExistsError = true;
        }
        else if(response.status == "200"){
          /*a user exists*/
          this.showEmailExistsError = false;
        }
      });
    },
    handleApplyForPassword(e) {
      e.preventDefault();

      if(this.validateEmailInput() && !this.showEmailExistsError){

        this.loader = this.showLoader(this.loader);

        csrf().then(() => {
          applyResetPassword(this.credentials).then(() => {

            this.$notify({
              duration: 2500,
              title: 'Passwort zurückzusetzen erfolgreich!',
              text: "Bitte überprüfen Sie Ihre E-Mails."
            });
            
            this.$router.push({name:'Home'});
            this.loading = false;
          })
          .catch( error => {

            var message = error.response.data;
            this.$notify({
              duration: 4500,
              type: 'error',
              title: 'Fehler bei dem zurückzusetzen des Passworts!',
              text: message
            });

          }).finally(()=>{
            this.loader = this.hideLoader(this.loader);
          });
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .apply-for-new-password {
    position: relative;
    background: #fff;
    padding-top: 110px;
    padding-bottom: 40px;

    .blank{
      display: flex;
      align-items: center;
      height: 60vh;
    }

    .blank-section {
      width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .container-fluid {
      max-width: 1000px;

      #apply-for-new-password-column{

        @media (max-width: 575px) {
          padding: 0px 50px;
        }

        @media (max-width: 400px) {
          padding: 0px 30px;
        }

      }


      h3.text-info {
        color: #00183E !important;
        margin-bottom: 30px;
      }

      .form-check{
        margin-bottom: 20px;      
      }

      .form-group {
        margin-bottom: 30px;

        .left-addon .glyphicon {
          left: 0px;
        }

        .inner-addon {
          position: relative;
        }

        .inner-addon .glyphicon {
          position: absolute;
          padding: 7px;
          pointer-events: none;
          color: #E30059;
        }

        .left-addon input {
          padding-left: 35px;
        }


      }
    }


  }
</style>
