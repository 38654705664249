<template>
    <div>
        <div class="karte">
            <Breadcrumb />
            <FilterButton :query="dataSet.query" @reload="queryChanged"/>

            <!-- Left Sidebar -->
            <div class="left-sidebar hide-on-mobile">
                <div class="panel-header ml50">
                    <div class="page-title inblock">
                        <h5 class="inblock">Kultur in Kiel</h5>
                    </div>

                    <button @click="showFilter = !showFilter" class="btn btn-fill icon-after float-right btn-focus-design" aria-label="Weitere Filter anzeigen">
                        Filter
                        <i class="material-icons" aria-hidden="true">{{ !showFilter ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</i>
                    </button>
                </div>

                <div class="filter-section mt20" v-if="showFilter">
                    <form action="">
                        <select name="" class="mb10" v-model="query.selectedCategory" data-placeholder="Kategorien" @change="applyChangesToQuery">
                            <option v-for="cat in categories" :value="cat.value" :key="cat.value">{{ cat.label }}</option>
                        </select>
                        <select name="" class="mb10" data-placeholder="Tags" @change="applyChangesToQuery" v-model="query.selectedTag">
                            <option v-for="tag in tags" :key="tag.value" :value="tag.value">{{ tag.label }}</option>
                        </select>
                    </form>
                </div>

                <div class="row card-sections" v-if="dataSet.meta!=null" tabindex="-1">
                    <div v-for="(content,$index) in dataSet.data" :key="content.id" :data-num="$index + 1">
                        <CardInstitution :class="isLastOfDataSet($index)" :id="'vertical-content-'+content.id" v-if="content.content_type_name == 'institution'" :content="content" :marginRight="'0px;'" :disableLink="true" @highlight="highlightThisContent"/>
                        <CardAttraction :class="isLastOfDataSet($index)" :id="'vertical-content-'+content.id" v-if="content.content_type_name == 'sehenswrdigkeit'" :marginRight="'0px;'" :content="content" @highlight="highlightThisContent" :disableLink="true"/>
                    </div>
                    <infinite-loading :identifier="'desktopInfiniteScroller'" @infinite="loadNext">
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </infinite-loading>
                </div>

            </div>

            <!-- Map Section -->
            <div class="map-section" :class="active===SECTIONS.KARTE?'map-section-map':'map-section-list'">
                <multiple-map ref="theMap" v-if="active === SECTIONS.KARTE && dataSet.data!=null" :contents="dataSet.data" @highlight="highlightThisContent"></multiple-map>

                <div class="only-on-mobile">
                    <div class="btn-custom btn-list-map-switch">
                        <button
                        :class="['btn btn-custom__item', active === SECTIONS.KARTE ? 'active' : '']"
                        @click="active = SECTIONS.KARTE"
                        >Karte</button>
                        <button
                        :class="['btn btn-custom__item', active === SECTIONS.LISTE ? 'active' : '']"
                        @click="active = SECTIONS.LISTE"
                        >Liste</button>
                    </div>

                    <div v-if="active === SECTIONS.KARTE" class="scroll-container horizontal-scroll">
                       <div v-for="(content,$index) in dataSet.data" :key="$index" :data-num="$index + 1">
                        <CardInstitution :location="'mapview'" :id="'horizontal-content-'+content.id" v-if="content.content_type_name == 'institution'" :content="content" :isSmall="true" :marginRight="'0px;'" :disableLink="true" @highlight="highlightThisContent" style="margin-bottom:0px;"/>
                        <CardAttraction :location="'mapview'" :id="'horizontal-content-'+content.id" v-if="content.content_type_name == 'sehenswrdigkeit'" :content="content" @highlight="highlightThisContent" :isSmall="true" :marginRight="'0px;'"/>
                    </div>
                    <infinite-loading :identifier="'mobileInfiniteScroller'" @infinite="loadNext">
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </infinite-loading>
                </div>

                <div v-if="active === SECTIONS.LISTE" class="list-container">
                   <div v-for="(content,$index) in dataSet.data" :key="$index" :data-num="$index + 1">
                    <CardInstitution v-if="content.content_type_name == 'institution'" :content="content" :marginRight="'0px;'" />
                    <CardAttraction v-if="content.content_type_name == 'sehenswrdigkeit'" :content="content" :marginRight="'0px;'"/>
                </div>
                <infinite-loading :identifier="'mobileInfiniteScrollerVertical'" @infinite="loadNext">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </div>

        </div>
    </div>
</div>


<div class="footer-margin hide-on-mobile">
</div>

</div>
</template>

<script>
    import Breadcrumb from '@/components/controls/Breadcrumb.vue';
    import CardInstitution from '@/components/cards/CardInstitution.vue';
    import CardAttraction from '@/components/cards/CardAttraction.vue';
    import FilterButton from '@/components/controls/FilterButton.vue';
    import MultipleMap from '@/components/map/multiple.vue';
    import InfiniteLoading from 'vue-infinite-loading';
    import VueScrollTo from 'vue-scrollto';
    import { isMobile } from 'mobile-device-detect';
    import { screenSizeMixin } from '@/mixins/screenSizeMixin';
    import { showLoader, hideLoader } from '@/utils/helpers';
    import { getMapContents } from '@/api/content';

    const SECTIONS = Object.freeze({
        KARTE: 'KARTE',
        LISTE: 'LISTE'
    });

    export default {
        name: 'MapView',
        mixins: [screenSizeMixin],
        components: {
            MultipleMap,
            CardAttraction,
            CardInstitution,
            Breadcrumb,
            FilterButton,
            InfiniteLoading,
        },
        data() {
            return {
                loader: null,
                isCurrentlyLoading: false,
                showFilter: false,
                SECTIONS,
                isItMobile: isMobile ? true : false,
                active: SECTIONS.KARTE,
                dataSet: {
                  total: 0,
                  data: null,
                  meta:null,
                  query: {
                    page:1,
                    limit: 150,
                    keyword: '',
                    type: 'view',
                    view_status: 1,
                    onlyWithLocation: true,
                    resourceType: 'view',
                    selectedAppId: this.$appId,
                    selectedContentTypeId: this.$institutionId
                },
            },
            scrollVerticalOptions: {
                container: '.card-sections',
                easing: 'ease-in',
                lazy: false,
                offset: -60,
                force: true,
                cancelable: true,
                x: false,
                y: true,
            },
            scrollHorizontalOptions: {
                container: '.horizontal-scroll',
                easing: 'ease-in',
                lazy: false,
                offset: -60,
                force: true,
                cancelable: true,
                x: true,
                y: false,
            },
            query:{
                selectedTag: null,
                selectedKeyword: null,
                selectedCategory: null,
            },
            attractionCategoryCardContent: {
                label: "Sehenswürdigkeiten",
                value: "Sehenswürdigkeiten"
            },
        }
    },
    created() {
        /*check if there are any preset query params*/
        if(Object.prototype.hasOwnProperty.call(this.$route.query,'category')){
            this.query.selectedCategory = decodeURI(this.$route.query.category);
            this.applyChangesToQuery();
        }
        else{
            this.getContents();
        }
    },
    watch: {
        $screenWidth(newWidth) {
            if(newWidth>=991){
                this.active = SECTIONS.KARTE;
            }
        },
    },
    computed:{
       categories(){
        var categories = JSON.parse(JSON.stringify(this.$store.getters.getCategories));
        /*removes the last element*/
        categories.pop();
        categories.push(this.attractionCategoryCardContent);
        return categories;
    },
    tags(){
        return this.$store.getters.getTags;
    },
},
methods: {
    showLoader,
    hideLoader,
    isLastOfDataSet(index){
        var length = this.dataSet.data.length-1;
        if(length==index){
            return "last-elem";
        }
        return "";
    },
    async getContents() {
        this.loader = this.showLoader(this.loader);
        const { limit, page } = this.dataSet.query;
        const { data, meta } = await getMapContents(this.dataSet.query);
        if(this.dataSet.data!=null){
            this.dataSet.data = this.dataSet.data.concat(data);
        }
        else{
            this.dataSet.data=data;
        }
        data.forEach((element, index) => {
            element['index'] = (page - 1) * limit + index + 1;
        });
        this.dataSet.meta = meta;
        this.dataSet.total = meta.total;
        this.loader = this.hideLoader(this.loader);
    },
    loadNext($state){
        /*try to load the next page*/
        if(this.dataSet.meta.current_page == this.dataSet.meta.last_page){
            /*do nothing we're already at the last page*/
            $state.complete();
        }
        else{
            this.isCurrentlyLoading=true;
            this.dataSet.query.page = this.dataSet.meta.current_page+1;
            this.getContents()
            .then(function () {
                $state.loaded();
            })
            .finally(() => { 
                //$state.complete(); 
                this.isCurrentlyLoading=false;
            });
        }
    },
    applyChangesToQuery(){
       this.dataSet.query.page=1;
       this.queryChanged(this.query);
   },
   queryChanged(query){
    this.dataSet.query.keyword = [];
    this.dataSet.query.subCategory = null;
    Object.keys(query).forEach((k) => {
        if(Array.isArray(query[k]) && query[k].length>0){
            var array = query[k];
            console.log(array);
            Object.keys(array).forEach((l) => {
                if(array[l]!=null){
                    this.dataSet.query.keyword.push(array[l]);
                }
            });
        }
        else if(!Array.isArray(query[k]) && query[k]!=null){
            if(query[k] === "Sehenswürdigkeiten"){
                this.dataSet.query.subCategory = query[k];
            }
            else{
                this.dataSet.query.subCategory = "Institutions";
                this.dataSet.query.keyword.push(query[k]);
            }
        }
    });
    this.dataSet.query.page=1;
    this.dataSet.data = null;
    this.getContents();
},
highlightThisContent(params){
    Object.keys(this.dataSet.data).forEach((k) => {
        this.dataSet.data[k].highlight = false;
        if(this.dataSet.data[k].id == params.content.id){
            this.dataSet.data[k].highlight = true;

            if(params.sender != "map"){
                /*open the popup*/
                this.$refs.theMap.openPopup(params.content);
                    //console.log(this.$refs.theMap);
                }
                else{
                   if(this.isItMobile){
                    VueScrollTo.scrollTo(('#horizontal-content-'+this.dataSet.data[k].id), '2000',this.scrollHorizontalOptions);
                }
                else{
                    VueScrollTo.scrollTo(('#vertical-content-'+this.dataSet.data[k].id), '2000',this.scrollVerticalOptions);
                }
            }
        }
    });
}
},
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .scroll-pagination-btn{
        width:100%;
        padding: 8px;
        text-align: center;
        color: $primary;
        border: 1px solid $primary;
        cursor: pointer;
    }

    .scroll-pagination-btn:hover{
        color: #fff;
        background-color: $primary;
    }

    .scroll-pagination-top{
        margin-top: 20px;
    }

    .scroll-pagination-bottom{
        margin-bottom: 20px;
    }

    .horizontal-scroll{
        width: 100vw;
    }

    .only-on-mobile{
        display: none;

        @media(max-width: 991px){
            display: block;
        }
    }

    .karte {
        position: relative;
        padding-top: 70px;
        display: flex;
        height: 100vh;
        margin-bottom: 146px;
        overflow: hidden;
        background: transparent;

        @media(max-width: 991px){
            height: calc(100vh - 40px);
        }

        .left-sidebar {
            flex: 0 450px;
            background: #fff;
            flex-direction: column;
            padding: 35px 20px 0 40px;

            &.hide-on-mobile{
                display: flex;

                @media(max-width: 991px){
                    display: none;
                }
            }


            .panel-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .card-sections {
                display: block;
                padding-left: 15px;
                margin-right: 0px;
                height: 100%;
                max-height: calc(100vh);
                overflow: hidden;
                overflow-y: auto;
                margin-top: 20px;

                .last-elem{
                    margin-bottom: 200px!important;
                }

                &::-webkit-scrollbar {
                  display: none;
              }

              &::-webkit-scrollbar-track {
                padding: 2px;
                margin-bottom: 20px;
                background-color: rgba($color: #c4c4c4, $alpha: 0.4)
            }

            &::-webkit-scrollbar {
                width: 10px;
                padding: 2px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
                box-shadow: inset 0 0 6px rgba($color: #a4a4a4, $alpha: 0.8);
                background-color: rgba($color: #c4c4c4, $alpha: 0.8);
                border: 2px solid rgba($color: #c4c4c4, $alpha: 0.4);
            }
        }
    }

    .map-section {
        flex: 1;
        background: #fff;
        overflow: auto;

        &.map-section-list{
            height: 100vh;
        }
        
        img {
            position: absolute;
            height: 100%;
            min-width: 100%;
        }

        .scroll-container {
            z-index: 1001;
            position: fixed;
            top: 110px;

            @media (max-width: 991px){ top: auto; bottom: 50px;}
        }

        .list-container {
            position: relative;
            top: 50px;
            max-width: 500px;
            margin: 0 auto;
            margin-bottom: 200px;
            padding: 0 15px;
        }

        .btn-custom {
            position: relative;
            display: block;
            width: 200px;
            margin: 0 auto;
            top: 22px;
            border-radius: 30px;
            box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);

            &.btn-list-map-switch{
                z-index: 1001;
                position: absolute;
                top: 110px;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &__item {
                padding: 10px 25px;
                border: 0;
                width: 100px;
                background: #fff;
                font-size: 16px;
                font-family: 'elliot-regular';
                font-weight: 400;
                text-transform: capitalize;
                color: $darkGray;
                border-radius: 0;

                &:first-child {
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                }
                &:last-child {
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                }

                &.active {
                    background: $infoColor;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }
    }

    @media (max-width: 991px) {
        margin-bottom: 0;
        height: 100vh;
    }
    
    @media (max-width: 350px) {
        padding-top: 60px;
    }
}
</style>
