<template>
    <div class="contact-info row" v-if="mail || phone || url">
        <div class="contact-info__item mb10" :class="getColClass()" v-if="mail">
            <span><a :href="'mailto:'+mail"><i class="material-icons" aria-hidden="true">mail</i>{{shortenedEmail}}</a></span>
        </div>
        <div class="contact-info__item mb10 phone" :class="getColClass()" v-if="phone">
            <span><i class="material-icons" aria-hidden="true">call</i><a :href="'tel:'+phone">{{phone}}</a></span>
        </div>
        <div class="contact-info__item mb10" :class="getColClass()" v-if="url">
            <span><i class="material-icons" aria-hidden="true">public</i><a :href="url" target="_blank">{{shortenedUrl}}</a></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "contactInformation",
        props: {
            url: {
                type: String,
                default: null,
            },
            phone: {
                type: String,
                default: null,
            },
            mail: {
                type: String,
                default: null,
            }
        },
        computed: {
            shortenedUrl(){
                if(this.url!=null){
                    return this.url.length>20?this.url.substring(0,20)+' ...':this.url;
                }
                return "";
            },
            shortenedEmail(){
                if(this.mail!=null){
                    return this.mail.length>15?this.mail.substring(0,18)+' ...':this.mail;
                }
                return "";
            },
        },
        methods: {
            getColClass(){
                if(this.url && this.phone && this.mail){
                    return "col-lg-4 col-md-6 col-12";
                }

                return "col-lg-6 col-md-6 col-12";            
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .row > *:nth-child(1){
        padding-left: 0px!important;
    }

    .contact-info {
        margin: 20px 0;
        font-size: 16px;

        @media (max-width: 991px) {
            margin-bottom: 0px;
        }

        &__item {
            padding-top: 20px;

            @media (min-width: 992px) {
                text-align:left;
            }

            @media (max-width: 992px) {
                padding-top: 10px;
                padding-left: 0px;
            }
            @media (max-width: 767px) {
                width: 100%;
                text-align: center!important;
            }


            @media (max-width: 500px) {
                font-size: 16px; 
            }


            &.phone{
                text-align:center;
            }

            &:nth-child(2){

                @media (max-width: 991px) {
                    text-align: right;
                }

            }

            @media (max-width: 575px){
                width: 100%;
                padding-top:10px;
                text-align: center!important;
            }

            i {
                margin-right: 10px;
                vertical-align: bottom;
                color: $secondary;
                display: inline;
            }

        }
    }

</style>
