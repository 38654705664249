<template>
    <div class="card card-news" :class="isCurrentlyActiveCard" :style="{marginBottom: marginBottom, marginRight: marginRight}" :title="linkToTitle">
        <a :href="institutionUrl" @click="navigateToView"  :title="linkToTitle" :alt="linkToTitle">
            <div class="card-img" :style="getStyle" :class="isSmall?'small':''">
                <div class="news-category">
                    <span>Sehenswürdigkeiten</span>
                </div>

                <button :title="linkToMerklisteOptions" :alt="linkToMerklisteOptions"  class="btn btn-link btn-favorite" v-if="!hideRememberThisBtn" :class="isInCollectionClass" @click="triggerAddToCollectionDialog">
                    <i class="material-icons" aria-hidden="true">anchor</i>
                </button>

            </div>
            <div class="card-detail">
                <h3 class="title">{{ title }}</h3>

            </div>
        </a>

        <AddContentToCollection :content="content" v-if="showAddToCollectionDialog" @closeModal="showAddToCollectionDialog=false" @reloadCollection="emitReloadCollection"></AddContentToCollection>
        
    </div>
</template>

<script>
    import AddContentToCollection from '@/components/modal/addContentToCollection/dialog.vue';
    import { getPlaceholderImage, getFieldValues, getNextAppointment, isOneOfTheAppointmentsNow, getWeekday, formatTime, getMonth, json_decode } from '@/utils/helpers';

    export default {
        name: "CardAttraction",
        components: {
            AddContentToCollection
        },
        props: {
            content: {
                type: Object,
                default: null,
                required: true
            },
            location: {
                type: String,
                default: 'normal'
            },
            isSmall: {
                type: Boolean,
                default: false
            },
            marginBottom: {
                type: String,
                default: '30px'
            },
            marginRight: {
                type: String,
                default: '15px'
            },
            hideDetails: {
                type: Boolean,
                default: false
            },
            disableLink: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
              showAddToCollectionDialog: false,
          };
      },
      computed: {
        linkToTitle(){
            return "Mehr Informationen zu: "+this.content.title;
        },
        linkToMerklisteOptions(){
            return "Optionen zum Merken für: "+this.content.title;
        },
        title(){
            if(this.content!=null){
                var title = this.content.title;
                if(this.isSmall){
                    return title.length>26?title.substring(0,26)+' ...':title;
                }
                else{
                    return title.length>30?title.substring(0,30)+' ...':title;                
                }
            }
            return "";
        },
        isLoggedIn(){
            if(this.$store.getters.getToken){
                return true;
            }
            return false;
        },
        collections(){
          return this.$store.getters.getCollections;
      },
      isInCollectionClass(){
        if(this.isLoggedIn && this.isContentInCollection()){
            return 'active';
        }
        return '';
    },
    isSafari(){
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if(iOS){
            return true;
        }
        else{
            return false;
        }
    },
    hideRememberThisBtn(){
        if(this.position == 'mapview' && this.isSafari == true){
            return true;
        }
        return false;
    },
    addressObj(){
        return getFieldValues(this.content,'adresse');
    },
    adresse(){
        if(this.addressObj!=null){
            return this.addressObj.street+', '+this.addressObj.zipcode+' '+this.addressObj.city;
        }
        return '';
    },
    teaserImages(){
        var pictures = getFieldValues(this.content,'teaser-bild');
        if(pictures != null){
            if(Array.isArray(pictures)){
                return pictures;
            }
            else{
                return [pictures];
            }
        }
        return null;
    },
    teaserImage(){
        var images = this.teaserImages;
        if(images!=null){
           var image = images[Math.floor(Math.random()*images.length)];
           image = json_decode(image);
           if(typeof image === "object"){
            return this.$backendUrl+image.path;
        }
        else{
            return this.$backendUrl+image;
        }
    }

    /*we'll need an alternative if there is no image*/
    return this.getPlaceholderImage(this.category);
},
isCurrentlyActive(){           
 if(this.appointments!=null){
     return isOneOfTheAppointmentsNow(this.appointments);
 }
 return false;
},
isCurrentlyActiveClass: function() {
    if (this.isCurrentlyActive){
        return 'highlight';
    }
    return '';
},
isCurrentlyActiveCard(){
    if(this.content.highlight == true){
        return "highlight";
    }
    return 'not-highlighted';
},
imageHeight: function() {
    return this.isSmall ? '105px' : '210px';
},
institutionUrl(){
    let props = this.$router.resolve({ 
      name: 'Anbieterprofil',
      params: { id: this.content.institution_id },
  });

    return props.href;
},
getStyle(){
    return 'max-height: '+this.imageHeight+'; backgroundImage: url("'+this.teaserImage+'")';
}
},
methods: {
   getFieldValues,
   getPlaceholderImage,
   json_decode,
   emitReloadCollection(collection_id){
    this.$emit('reloadCollection',collection_id);
},
navigateToView(event){
    if(this.disableLink){
        this.$emit('highlight',{sender:'institution-card', content: this.content});
        event.preventDefault()
    }
},
triggerAddToCollectionDialog(event){
    event.preventDefault();
    this.showAddToCollectionDialog=true;
},
isContentInCollection(){
    if(this.collections!=null){
        for (var prop in this.collections) {
            var collectionGroup = this.collections[prop];
            for(var i=0; i<collectionGroup.length; i++){
                var currentCollection = collectionGroup[i];
                if(currentCollection!=null && Object.prototype.hasOwnProperty.call(currentCollection,'contents') && currentCollection.contents.length>0){
                    for(var j=0; j<currentCollection.contents.length; j++){
                        if(currentCollection.contents[j].id == this.content.id){
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
},
}
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .card{
        position: relative;
        border-radius: 5px;
        border-bottom-left-radius: 30px;
        border: none;
        min-width: 264px;
        margin-right: 15px;
        box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.2);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover,
        &:focus,
        &:active {
            color: $primary;
            box-shadow: -5px 5px 15px 0px rgba($color: $primary, $alpha: 0.35);
        }

        &.highlight{
            border: 3px solid $secondary;
        }

        .card-img {
            position: relative;
            overflow: hidden;
            min-height: 120px;
            background-size:cover;
            background-repeat: no-repeat;
            background-position: center center;

            &.small{
                min-height: 80px;

                .news-category {
                    display:none;
                }
            }

            .news-category {
                position: absolute;
                top: 20px;
                background: rgba($color: #fff, $alpha: 0.85);
                padding: 10px 20px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;

                span {
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-size: 14px;
                    color: #00183E;
                    line-height: 1.25;
                    font-family: 'elliot-bold', sans-serif;
                    font-weight: 700;
                }
            }

            img {
                width: 100%;
                overflow: hidden;
            }
        }

        .card-detail {
            padding: 20px;

            .title {
                font-size: 14px;
                margin-bottom: 10px;
                color: #00183E;
                line-height: 1.25;
                font-family: 'elliot-bold', sans-serif;
                font-weight: 700;
            }

            &__features {
                display: flex;


                &.small{
                    display: block;
                }

                li {
                    flex: 1;
                    display: inline-flex;
                    vertical-align: middle;
                    align-items: center;

                    i, img {
                        margin-right: 10px;
                    }

                    img {
                        display: inline-block;
                        max-height: 24px;
                    }

                    &.highlight {
                        color: $success !important;

                        span {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

</style>
