import Vue from 'vue'
import Vuex from 'vuex'
import { login, getUser, logout } from '@/api/auth';
import { getCollectionsOfUser } from '@/api/collection';
import { getAllowedValuesForFieldType } from '@/api/fieldType';
import { getAllowedValuesFromFieldTypes } from '@/utils/helpers';
import createPersistedState from "vuex-persistedstate";
import { search } from '@/api/content';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    roles: null,
    token: null,
    tags: null,
    login_time: null,
    categories: null,
    institutions: null,
    collections: null,
    langcode: "de",
  },
  mutations: {
    SET_TOKEN: (state, token ) => {
      state.token = token;
      sessionStorage.setItem('token', token);
    },
    SET_USER: (state, user ) => {
      state.user = user;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_CATEGORIES: (state, categories ) => {
      state.categories = categories;
    },
    SET_INSTITUTIONS: (state, institutions) => {
      state.institutions = institutions;
    },
    SET_USER_COLLECTIONS: (state, collections) => {
      state.collections = collections;
    },
    SET_TAGS: (state, tags) => {
      state.tags = tags;
    },
    SET_LOGIN_TIME: (state, time) => {
      state.login_time = time;
    },
  },
  getters: {
    getInstitutions(state) {
      return state.institutions;
    },
    getLoginTime(state) {
      return state.login_time;
    },
    getCategories(state) {
      return state.categories;
    },
    getCollections(state){
      return state.collections;
    },
    getTags(state) {
      return state.tags;
    },
    getRoles(state) {
      if(state.user !=null && Object.prototype.hasOwnProperty.call(state.user,'roles')){
        return state.user.roles;
      }
      return null;
    },
    getUser(state) {
      return state.user;
    },
    getCurrentLangcode(state) {
      return state.langcode;
    },
    getToken(state) {
      if(state.token === null){
        /*trying to get the token from sessionStorage*/
        var token = sessionStorage.getItem('token');
        /*set the token to the token from sessionStorage - but only if that token is not null*/
        if(token != null && token != "null"){
          state.token=token;
        }
      }
      return state.token;
    },
  },
  actions: {
    async loadInstitutions({commit,getters}){
      if(getters.getInstitutions==null || !getters.getInstitutions || getters.getInstitutions.length==0){

       var dataSet = {
        data: null,
        query: {
          page:1,
          limit: 50000,
          view_status: 1,
          keyword: '',
          onlyWithLocation: false,
          type: 'view',
          resourceType: 'view',
          selectedAppId: Vue.prototype.$appId,
          selectedContentTypeId: Vue.prototype.$institutionId,
          sort: {
            prop: 'title',
            order: 'ascending'
          },
        },
      };

      await search(dataSet.query).then(response => {
        commit('SET_INSTITUTIONS', response.data);
      })
      .catch(error => {
        console.log(error);
      });
    }
  },
  loadUserCollections({commit,getters}){
    /*get the current user*/
    var user = getters.getUser;

    if(user!=null){
      return new Promise((resolve, reject) => {
        getCollectionsOfUser(user)
        .then(response => {
          commit('SET_USER_COLLECTIONS', response);
          resolve(response); 
        })
        .catch(error => {
          console.log(error);

          this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Fehler!',
            text: 'Die Merklisten konnten nicht geladen werden.'
          });
          
          reject(error.response)
        });

      });
    }
  },
  loadCategories({commit, getters},){
    /*we'll be using the content_type_id 1 - that are the institutions. It's important that changes are made in both ctypes - the institutions and the angebote*/
    var content_type_id = 1;
    /*fieldType to be loaded by tname, lang_id and contenttype_id*/
    var fieldTypeTechnicalName = "kultur_-_kategorien";
    /*load the categories from the server*/
    getAllowedValuesForFieldType({ctype_id: content_type_id, fieldTypeName: fieldTypeTechnicalName ,langcode:getters.getCurrentLangcode}).then(response => {
      /*that should be the fType*/
      var fType = response.data;
      /*check if we have allowed_values*/
      var data = getAllowedValuesFromFieldTypes(fType);
      /*let's add a defaultValue to the beginning of the data Array*/
      if(data!=null){
        data.unshift({'label':'Kategorien', 'value': null, 'id':null});
        data.push({'label':'Alle', 'value': null, 'id':null});
      }
      /*set the Categories in the store*/
      commit('SET_CATEGORIES', data);
    })
    .catch(error => {
      console.log(error);
    });
  },
  loadTags({commit, getters},){
    /*we'll be using the content_type_id 1 - that are the institutions. It's important that changes are made in both ctypes - the institutions and the angebote*/
    var content_type_id = 1;
    /*fieldType to be loaded by tname, lang_id and contenttype_id*/
    var fieldTypeTechnicalName = "kultur_-_tags";
    /*load the categories from the server*/
    getAllowedValuesForFieldType({ctype_id: content_type_id, fieldTypeName: fieldTypeTechnicalName ,langcode:getters.getCurrentLangcode}).then(response => {
      /*that should be the fType*/
      var fType = response.data;
      /*check if we have allowed_values*/
      var data = getAllowedValuesFromFieldTypes(fType);
      /*let's add a defaultValue to the beginning of the data Array*/
      if(data!=null){
        data.unshift({'label':'Tags', 'value': null, 'id':null});
        data.push({'label':'Alle', 'value': null, 'id':null});
      }
      /*set the Categories in the store*/
      commit('SET_TAGS', data);
    })
    .catch(error => {
      console.log(error);
    });
  },
  loadUser({ commit }) {
    return new Promise((resolve, reject) => {
      getUser().then(response => {
        commit('SET_USER', response.data);
        resolve(response); 
      })
      .catch(error => {
        console.log(error);
        reject(error.response)
      });
    });
  },
  login ({commit},payload) {
    return new Promise((resolve, reject) => {
      /*a new login*/
      login(payload).then(response => {
        /*set the login-time*/
        commit('SET_LOGIN_TIME',Date.now());
        /*set the token*/
        commit('SET_TOKEN', response.data.token);
        /*set the user*/
        commit('SET_USER', response.data.user);
        resolve(response); 
      })
      .catch(error => {
        reject(error.response)
      });
    });
  },
  resetUserAndToken({commit}){
    /*set the token*/
    commit('SET_TOKEN', null);
    /*set the user*/
    commit('SET_USER', null);
    /*set the login-time*/
    commit('SET_LOGIN_TIME', null);
  },
  logout({commit}){
    return new Promise((resolve, reject) => {
      /*a new login*/
      logout().then(response => {
        resolve(response); 
      })
      .catch(error => {
        reject(error.response.data)
      })
      .finally(() => {
        commit('SET_TOKEN', null);
        /*set the user*/
        commit('SET_USER', null);
        /*Logout the user*/
        commit('SET_LOGIN_TIME',null);
      });
    });
  }
},
modules: {
},
plugins: [createPersistedState()]
});
