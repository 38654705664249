<template>
    <header>
        <div class="container-fluid">
            <!-- Desktop Header -->
            <div class="desktop">
                <div class="logo" tabindex="-1">
                    <router-link tabindex="-1" to="/"><img :src="currentLogo" alt="Logo" tabindex="0"></router-link>
                </div>

                <!-- Main Menu -->
                <nav class="main-menu ml-auto">
                    <Navigation/>
                </nav>
            </div>

            <!-- Mobile Headear -->
            <div class="mobile">
                <button class="btn btn-link btn-icon btn-menu"
                aria-label="Menü" 
                @click="mobileMenu = !mobileMenu">
                <i class="material-icons" aria-hidden="true">{{ mobileMenu ? 'close' : 'menu'}}</i>
            </button>

            <div class="logo">
                <router-link to="/"><img :src="kkkLogo" alt="Logo"></router-link>
            </div>

           <!-- 
           <router-link class="btn btn-link btn-icon btn-round btn-header ml-auto" to="/karte" aria-label="Link zur Karte" alt="Karte"><i class="material-icons" aria-hidden="true">place</i></router-link>

            <router-link class="btn btn-link btn-icon btn-round btn-header" to="/kultur-kalender" aria-label="Link zum Kulturkalender" alt="Kulturkalender"> <i class="material-icons" aria-hidden="true">date_range</i></router-link>
        -->

    </div>

    <div class="mobile-nav"
    v-if="mobileMenu"
    @click="mobileMenu = false">
    <div class="overlay"></div>

    <Navigation/>

</div>

<intro-modal v-if="isFirstLoginEver"/>

</div>
</header>
</template>

<script>
    import Navigation from './components/nav.vue';
    import IntroModal from '@/components/modal/introModal.vue';

    export default {
        name: "AppHeader",
        components: {
          IntroModal,
          Navigation,
      },
      data() {
        return {
            lhkLogo: '/assets/logos/kiel_logo.png',
            kkkLogo: '/assets/logos/kkk_logo.png',
            currentLogo: '/assets/logos/kiel_logo.png',
            mobileMenu: false,
            showIntro: true,
        }
    },
    computed:{
        currentPathName(){
            return this.$route.name;
        },
        user(){
          return this.$store.state.user;
      },
      isFirstLoginEver(){
        if(this.user!=null){
            return this.user.is_first_login_ever;
        }
        return null;
    },
},
watch: {
    currentPathName: function () {
        if (this.currentPathName!="Home"){
            this.currentLogo = this.kkkLogo;
        }
        else{
            this.currentLogo = this.lhkLogo;
        }
    }
},
created() {
    window.addEventListener("scroll", this.handleScroll);
},
unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
},
methods: {
    handleScroll() {
        /*is only used if the current path is home - we need to show the default lhk kiel Logo*/
        if (this.currentPathName=="Home"){
            if (window.scrollY > 100) {
                return (this.currentLogo = this.kkkLogo);
            }
            if (window.scrollY <= 100) {
                if (this.currentLogo != this.lhkLogo) {
                    return (this.currentLogo = this.lhkLogo);
                }
            }
        }
    },
},
}
</script>

<style lang="scss">
    @import '@/scss/_variables.scss';

    header {
        position: fixed;
        width: 100%;
        background: #fff;
        padding: 20px 0;
        box-shadow: 0px 5px 10px 0px rgba($color: $primary, $alpha: 0.3);
        z-index: 99999;
        max-height: 70px;

        .desktop {
            display: flex;
            align-items: center;
            vertical-align: middle;
            height: 30px;

            .main-menu {
                .navigation {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    li {
                        position: relative;
                        float: left;
                        margin-left: 30px;

                        .router-link-exact-active {
                            font-weight: 700 !important;
                        }

                        a:not(.btn) {
                            color: $primary;
                            display: inline-block;
                            font-weight: 400;
                            position: relative;
                            font-family: 'elliot-regular', sans-serif;
                            -webkit-transition: 0.5s;
                            transition: 0.5s;
                            opacity: 1;
                            font-size: 18px;
                            padding: 5px 0;

                            &:hover,
                            &:active,
                            &:focus {
                                font-family: 'elliot-regular', sans-serif;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }

        .mobile {
            display: none;
            vertical-align: middle;
            align-items: center;

            .btn-menu {
                color: #465A69;
                margin-right: 1.5rem;

                @media (max-width: 450px) {
                    margin-right: 10px;
                }
            }

            .logo a h4 {
                font-size: 1.2rem;
                margin-bottom: 0;

                @media (max-width: 500px) {
                    font-size: 1rem;
                }

                @media (max-width: 350px) {
                    font-size: 0.8rem;
                }
            }

            .logo img{

                margin-top: -7px;

                @media (max-width: 700px) {
                    width:250px;
                    margin-top: 0px;
                }

                @media (max-width: 500px) {
                    margin-top: -4px;
                    width: 200px;
                }

                @media (max-width: 420px) {
                    margin-top: -2px;
                    width: 190px;
                }

                @media (max-width: 365px) {
                    width: 170px;
                }
            }

            .btn-header {
                background: $primary;
                color: #fff;
                margin-left: 1rem;
                width: 40px;
                height: 40px;

                @media (max-width: 350px) {
                    width: 30px;
                    height: 30px;
                }

                i.material-icons {
                    font-size: 30px;

                    @media (max-width: 350px) {
                        font-size: 20px;
                        top: -9px;
                    }
                }
            }

            @media (max-width: 1200px) {
                display: flex;
            }

            .logo {
                margin: 0 auto;
            }
        }

        .mobile-nav {

            .overlay {
                background: rgba($color: #000000, $alpha: 0.55);
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: 1;
            }

            .navigation {
                background: #fff;
                position: absolute;
                z-index: 999;
                padding: 30px 20px;
                min-width: 280px;
                left: 0;
                top: 0;
                height: 100vh;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 30px;
                box-shadow: 0px 5px 10px 0px rgba($color: $primary, $alpha: 0.3);
                display: flex;
                flex-direction: column;
                z-index: 2;

                li {
                    order: 1;

                    padding: 12px 0;
                    a:not(.btn) {
                        color: rgba($color: $primary, $alpha: 0.80);
                        display: inline-block;
                        font-weight: 400;
                        position: relative;
                        font-family: 'elliot-regular',sans-serif;
                        -webkit-transition: 0.5s;
                        transition: 0.5s;
                        opacity: 1;
                        font-size: 18px;

                    }

                    a.btn {
                        border: 0;
                        padding: 0;
                        color: rgba($color: $primary, $alpha: 0.6);

                        &:hover,
                        &:active,
                        &:focus {
                            background: none;
                        }
                    }

                    &.btn-search {
                        order: 0;

                        .btn.btn-search {
                            width: 100%;
                            padding: 0.575rem 2.25rem !important;
                            text-align: left;
                            color: #fff;
                            font-size: 15px;

                            &:hover,
                            &:active,
                            &:focus {
                                background: $secondary;
                            }

                        }
                    }
                }
            }

            @media (max-width: 350px) {
                top: 50px
            }
        }

        @media (max-width: 1200px) {
            padding: 15px 0;
        }
    }

    .btn-menu {
        border: 2px solid $secondary;
        border-radius: 3px;
        border-bottom-left-radius: 20px;
        text-transform: uppercase;

        a {
            color: $secondary !important;
            font-weight: 700 !important;
            font-size: 12px !important;
            padding: 7px 20px !important;
        }
    }
</style>
